<template>
  <div
    class="h-full w-full relative bg-[#262626]"
    @click="$refs.blockPostDropdown.closeDropdown()">
    <!-- Navigation buttons -->
    <div class="absolute -left-10 bottom-0 top-0 z-10 h-full flex flex-col justify-center">
      <button
        type="button"
        :disabled="isFirstSlide"
        :class="{ 'opacity-50': isFirstSlide }"
        class="swiper-button-prev-unique my-1">
        <InlineSvg
          :src="require('@/assets/svg/light/arrow-circle-up.svg')"
          class="text-gray-300 w-8 h-8"/>
      </button>

      <button
        type="button"
        :disabled="isLastSlide"
        :class="{ 'opacity-50': isLastSlide }"
        class="swiper-button-next-unique my-1">
        <InlineSvg
          :src="require('@/assets/svg/light/arrow-circle-down.svg')"
          class="text-gray-300 w-8 h-8"/>
      </button>
    </div>

    <!-- Close -->
    <button
      v-show="isMobile && showClose"
      class="btn p-1 absolute top-2 left-2 z-50 post-shadow"
      @click="emitClose">
      <InlineSvg
        :src="require('@/assets/svg/design/close-arrow.svg')"
        class="text-gray-100 w-6 h-6 post-shadow"/>
    </button>

    <!-- Maximize/Minimize attachment -->
    <button
      v-if="showMaximize"
      type="button"
      class="absolute z-40 right-2.5 top-9 text-white post-shadow p-1 text-xl"
      @click="maximize = !maximize">
      <InlineSvg
        :src="require(`@/assets/svg/design/${ maximize ? 'collapse' : 'expand' }.svg`)"
        class="w-6 h-6"/>
    </button>

    <img
      v-if="posts.length"
      v-show="currentItem.type === 0"
      :class="[maximize ? 'object-cover' : 'object-contain']"
      class="absolute z-0 inset-0 w-full h-full block object-center pointer-events-none"
      :src="getPreviewImage(currentItem)"
      :alt="currentItem.description">

    <!-- Video -->
    <div
      v-if="posts.length"
      :class="{ 'opacity-0': inMove || inTransition }"
      class="absolute inset-0 pointer-events-none max-w-md mx-auto w-full h-full">
      <VideoNext
        ref="video"
        v-model:buffer-progress="buffered"
        :maximize-video="maximize"
        playsinline
        :is-visible="currentItem.type === 0 && (!currentItem.locked || (currentItem.locked && currentItem.videoUrl))"
        :is-partly-available="isPartlyAvailable"
        :src="currentItem?.videoUrl ? currentItem.videoUrl : ''"
        @ended="videoEnded"
        @playing="isPlaying = true"
        @paused="isPaused = $event; isPlaying = false"/>
    </div>

    <!-- Block/Report user handler -->
    <div
      class="absolute top-4 right-4 z-40"
      @click.stop>
      <Dropdown
        ref="blockPostDropdown"
        :items="[
          { text: $t('creator.block.report_this_content'), callback: blockPost },
          { text: $t('creator.block.report_this_creator'), callback: reportUser }
        ]">
        <!-- dots ... -->
        <button
          id="options-menu"
          type="button"
          class="text-white rounded-full flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
          <svg
            class="h-5 w-5"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6.17993 10.5C6.17993 11.0304 5.96922 11.5391 5.59415 11.9142C5.21907 12.2893 4.71036 12.5 4.17993 12.5C3.6495 12.5 3.14079 12.2893 2.76572 11.9142C2.39065 11.5391 2.17993 11.0304 2.17993 10.5C2.17993 9.96957 2.39065 9.46086 2.76572 9.08579C3.14079 8.71071 3.6495 8.5 4.17993 8.5C4.71036 8.5 5.21907 8.71071 5.59415 9.08579C5.96922 9.46086 6.17993 9.96957 6.17993 10.5ZM12.1799 10.5C12.1799 11.0304 11.9692 11.5391 11.5941 11.9142C11.2191 12.2893 10.7104 12.5 10.1799 12.5C9.6495 12.5 9.14079 12.2893 8.76572 11.9142C8.39065 11.5391 8.17993 11.0304 8.17993 10.5C8.17993 9.96957 8.39065 9.46086 8.76572 9.08579C9.14079 8.71071 9.6495 8.5 10.1799 8.5C10.7104 8.5 11.2191 8.71071 11.5941 9.08579C11.9692 9.46086 12.1799 9.96957 12.1799 10.5ZM18.1799 10.5C18.1799 11.0304 17.9692 11.5391 17.5941 11.9142C17.2191 12.2893 16.7104 12.5 16.1799 12.5C15.6495 12.5 15.1408 12.2893 14.7657 11.9142C14.3906 11.5391 14.1799 11.0304 14.1799 10.5C14.1799 9.96957 14.3906 9.46086 14.7657 9.08579C15.1408 8.71071 15.6495 8.5 16.1799 8.5C16.7104 8.5 17.2191 8.71071 17.5941 9.08579C17.9692 9.46086 18.1799 9.96957 18.1799 10.5Z"/>
          </svg>
        </button>
      </Dropdown>
    </div>

    <swiper
      v-if="items.length"
      class="h-full"
      direction="vertical"
      :speed="200"
      :pagination="{
        enabled: false
      }"
      :watch-slides-progress="true"
      :short-swipes="true"
      :navigation="{
        nextEl: '.swiper-button-next-unique',
        prevEl: '.swiper-button-prev-unique'
      }"
      :keyboard="true"
      :initial-slide="values.initialIndex"
      @reach-end="reachEnd"
      @slide-change="onSlideChange"
      @slider-move="sliderMove"
      @touch-end="touchEnd"
      @active-index-change="activeIndexChange"
      @after-init="afterInit"
      @slide-change-transition-start="slideChangeTransitionStart"
      @slide-change-transition-end="slideChangeTransitionEnd">
      <swiper-slide
        v-for="(item, index) in postsWithUsers"
        :key="item.id"
        :class="{ 'tint tinted': item.locked && !item.videoUrl }"
        @click.stop="click(index, 'slide')">
        <div
          v-if="item.locked && !item.videoUrl && blurAlternativeEnabled"
          class="bg-[#262626] h-full w-full absolute inset-0 overflow-hidden">
          <img
            class="w-full h-full relative z-10"
            alt="Lock decoration"
            src="@/assets/svg/design/lock-smile-decoration-big.svg">
        </div>

        <img
          v-else
          v-show="inMove || inTransition || (item.type === 1 || (item.type === 0 && !item.videoUrl))"
          class="w-full h-full block object-center"
          :class="[maximize ? 'object-cover' : 'object-contain']"
          :src="getPreviewImage(item)"
          :alt="item.description">

        <!-- Play button -->
        <div
          v-if="!isEnded && (item.type === 0 && !item.locked || (currentItem.locked && currentItem.videoUrl) && item?.videoUrl) && isPaused"
          class="absolute z-10 inset-0 flex items-center justify-center pointer-events-none post-shadow">
          <InlineSvg
            :src="require('@/assets/svg/duotone/play.svg')"
            class="h-20 w-20 text-white"/>
        </div>

        <!-- PlayLoader -->
        <div
          v-show="loaderAllowed && isCurrentItemVideo && !(inTransition || inMove) && !isPaused && !isClosing && !isPlaying"
          class="absolute z-20 inset-0 p-4 flex flex-col items-center justify-center">
          <span class="loader !border-2 w-12 h-12"/>
        </div>

        <!-- Content -->
        <!-- Show if content is locked -->
        <div
          v-if="item.locked && !item.videoUrl"
          class="w-full h-full flex flex-col items-center justify-center absolute inset-0 z-20">
          <div class="p-4">
            <div class="flex flex-col items-center justify-center post-shadow mb-1">
              <InlineSvg
                :src="require('@/assets/svg/design/lock.svg')"
                class="text-gray-100 w-4 h-6"/>

              <b class="font-bold text-white ml-2 text-[22px]">
                {{ $t('posts.private_content') }}
              </b>
            </div>

            <p class="text-white post-shadow text-sm text-center">
              <template v-if="item.price">
                {{ $t('posts.unlock_to_see') }}
              </template>

              <template v-else>
                {{ $t('posts.subscribe_to_see') }}
              </template>
            </p>
          </div>
        </div>

        <PostAvailability
          v-if="item.locked && item.videoUrl && isEnded"
          :post="item"
          :is-ended="isEnded"
          :user="item.user"
          class="absolute inset-0 z-40"
          @subscribed="$emit('subscribed', $event)"
          @replay="replay"
          @unlocked="$emit('unlocked', {
            post: $event,
            index: index
          })"/>

        <PostHandlers
          :post="item"
          :user="item.user || {}"
          :like="like"
          :share="share"
          :message-me="messageMe"
          class="absolute inset-0 z-30"
          @avatar-clicked="$emit('avatar-clicked')"
          @modal-closed="$refs.video.playVideo()"
          @pause="$refs.video.pauseVideo()"
          @subscribed="$emit('subscribed', $event)"
          @unlocked="$emit('unlocked', {
            post: $event,
            index: index
          })"
          @message-sent="$emit('messageSent')"/>
      </swiper-slide>
    </swiper>
  </div>

  <Modal
    v-slot="{ values, closeModal }"
    ref="reportModal"
    name="ReportUser">
    <BlockUserContent
      :heading="$t('creator.block.report_user')"
      :values="values"
      type="report"
      @close="closeModal()"/>
  </Modal>
</template>

<script>
  import { Swiper, SwiperSlide } from 'swiper/vue'
  import SwiperCore, { Keyboard, Navigation } from 'swiper'
  import { mapState, mapWritableState } from 'pinia'
  import { useAppStore } from '@/stores/app'
  import { useAuthStore } from '@/stores/auth'
  import { useChatStore } from '@/stores/chat'
  import VideoNext from '@/components/PostsSwiper/VideoNext.vue'
  import PostAvailability from '@/components/PostsSwiper/PostAvailability.vue'
  import PostHandlers from '@/components/PostsSwiper/PostHandlers.vue'
  import BlockUserContent from '@/components/ModalContents/BlockUserContent.vue'
  import Dropdown from '@/components/Functional/Dropdown.vue'
  import helpers from '@/helpers'
  import app from '@/main'
  import uniqid from 'uniqid'

  SwiperCore.use([Navigation, Keyboard])

  let timeoutID = null

  export default {
    name: 'ThePostsSwiper',
    components: {
      Swiper,
      SwiperSlide,
      VideoNext,
      PostAvailability,
      PostHandlers,
      BlockUserContent,
      Dropdown
    },
    props: {
      values: {
        type: Object,
        required: false,
        default: () => ({
          initialIndex: 0
        })
      },
      posts: {
        type: Array,
        default: () => [],
        required: true
      },
      users: {
        type: Array,
        default: () => [],
        required: true
      },
      showClose: {
        type: Boolean,
        default: false,
        required: false
      },
      showMaximize: {
        type: Boolean,
        default: false,
        required: false
      }
    },
    emits: [
      'reachEnd',
      'slideChanged',
      'liked',
      'shared',
      'subscribed',
      'close',
      'unlocked',
      'messageSent',
      'afterInit',
      'avatar-clicked'
    ],
    data () {
      return {
        inMove: false,
        inTransition: false,
        isPaused: true,
        isEnded: false,
        buffered: 0,
        currentIndex: 0,
        maximize: true,
        isClosing: false,
        isPlaying: false,
        loaderAllowed: false
      }
    },
    computed: {
      items () {
        return this.posts
      },
      ...mapState(useAppStore, [
        'isMobile'
      ]),
      ...mapState(useAuthStore, [
        'isAuthenticated',
        'isTokenExist',
        'authOptions'
      ]),
      ...mapWritableState(useAuthStore, [
        'signupIsVisible'
      ]),
      ...mapWritableState(useChatStore, [
        'chatId'
      ]),
      isPartlyAvailable () {
        return !!(this.currentItem.type === 0 && this.currentItem.locked && this.currentItem.videoUrl)
      },
      blurAlternativeEnabled () {
        return this.authOptions?.blurAlternativeEnabled
      },
      currentItem () {
        return this.postsWithUsers[this.currentIndex]
      },
      isCurrentItemVideo () {
        const item = this.currentItem
        return item.type === 0 && (!item.locked || (item.locked && item.videoUrl))
      },
      isFirstSlide () {
        return this.currentIndex === 0
      },
      isLastSlide () {
        return this.currentIndex === this.items.length - 1
      },
      postsWithUsers () {
        return this.items.map((item) => ({
          ...item,
          user: this.users.find((user) => Number(user.userId) === Number(item.userId))
        }))
      }
    },
    watch: {
      currentIndex () {
        if (!this.isCurrentItemVideo) {
          this.$refs.video.pauseVideo()
        }
      },
      currentItem () {
        this.chatId = this.postsWithUsers[this.currentIndex]?.user?.userId
      }
    },
    mounted () {
    },
    methods: {
      getPreviewImage (item) {
        if (item.type === 0) {
          if (item.locked && !item.videoUrl) {
            return item.blurredPreviewImageUrl
          }
          else {
            return item?.videoPreviewImageUrls.r640x960
          }
        }
        else {
          if (item.locked) {
            return item.blurredPreviewImageUrl
          }
          else {
            return item?.imageUrls.r640x960
          }
        }
      },
      sliderMove () {
        this.inMove = true
      },
      touchEnd () {
        this.inMove = false
      },
      slideChangeTransitionStart () {
        this.inTransition = true
      },
      afterInit () {
        this.currentIndex = this.values.initialIndex
        this.$emit('afterInit', { index: this.currentIndex })
        this.viewPost()
        this.handleLoaderAvailability()
      },
      slideChangeTransitionEnd () {
        this.inTransition = false
      },
      activeIndexChange (swiper) {
        this.currentIndex = swiper.realIndex

        this.viewPost()
      },
      onSlideChange (swiper) {
        this.currentIndex = swiper.realIndex

        this.$emit('slideChanged', { index: this.currentIndex })

        this.handleLoaderAvailability()

        if (this.isProduction) {
          // eslint-disable-next-line no-undef
          gtag('event', 'Swipe', {
            event_category: 'Videos'
          })
        }
      },
      async emitClose () {
        await this.$emit('close')
        this.isClosing = true
      },
      async viewPost () {
        if (this.currentItem.locked && !this.currentItem.videoUrl) return // Video
        if (this.currentItem.locked && this.currentItem.type === 1) return // Image

        await app.wsp.sendRequest({
          data: {
            postUserId: this.currentItem.userId,
            postNumber: this.currentItem.number
          },
          method: 'post.viewed'
        })
      },
      click (index) {
        this.currentIndex = index

        if (this.currentIndex === undefined || this.currentItem === undefined) {
          return
        }

        // If post is video or partially locked video
        if ((this.currentItem.type === 0) || (this.currentItem.locked && this.currentItem.videoUrl)) {
          // Play video if slider is not in move
          if (!this.inMove && !this.inTransition && !event?.target?.closest('.play-prevent')) {
            this.$refs.video.toggleVideo()
          }
        }
      },
      videoEnded () {
        this.isEnded = true
      },
      replay () {
        this.$refs.video.playVideo()
        this.isEnded = false
      },
      reachEnd () {
        this.$emit('reachEnd')
      },
      async like () {
        if (!this.isTokenExist) {
          helpers.showSignUp()

          return
        }

        const method = this.currentItem.liked ? 'post.unlike' : 'post.like'

        // Send message to creator
        if (method === 'post.like') {
          const fullURL = window.location.origin
          const message = `I like your post ❤️ \n ${fullURL}/${this.currentItem.id}`

          const res = await app.wsp.sendRequest({
            data: {
              postUserId: this.currentItem.userId,
              postNumber: this.currentItem.number,
              autoMessageText: message
            },
            method: method
          })

          console.log(123, res)
        } else {
          const res = await app.wsp.sendRequest({
            data: {
              postUserId: this.currentItem.userId,
              postNumber: this.currentItem.number
            },
            method: method
          })

          if (res.error) {
            console.log(res.error.message)
          }
        }

        this.$emit('liked', this.currentItem.id)
      },
      async sendMessage (message, chatId, surveyResultId) {
        try {
          const res = await this.wsp.sendRequest({
            data: {
              text: message || '',
              receiverId: chatId,
              clientId: uniqid(),
              ttlAfterRead: null,
              highlighted: true,
              surveyResultId: surveyResultId ? surveyResultId.toString() : null
            },
            method: 'message.send'
          })

          console.log(res)
        } catch (e) {
          console.log(e)
        }
      },
      async share () {
        if (!this.isTokenExist) {
          helpers.showSignUp()

          return
        }

        await navigator.share({ url: `${process.env.VUE_APP_REDIRECT_URL || 'https://one2fan.com'}/${this.currentItem.id}` })

        const res = await app.wsp.sendRequest({
          data: {
            postUserId: this.currentItem.userId,
            postNumber: this.currentItem.number
          },
          method: 'post.shared'
        })

        if (res.error) {
          console.log(res.error.message)
        }

        this.$emit('shared', this.currentItem.id)
      },
      messageMe (userId) {
        if (this.$route.fullPath === `/chat/${userId}`) {
          this.$emit('close')
        }
      },
      handleLoaderAvailability () {
        this.loaderAllowed = false

        if (timeoutID) {
          clearTimeout(timeoutID)
        }

        timeoutID = setTimeout(() => {
          this.loaderAllowed = true
        }, 2000)
      },
      blockPost () {
        if (this.isTokenExist) {
          this.$refs.blockPostDropdown.closeDropdown()
        }
        else {
          helpers.showOnbordingOrSignUp()
        }
      },
      reportUser () {
        if (this.isTokenExist) {
          const user = this.postsWithUsers[this.currentIndex].user

          if (!user.userId || !user.username) {
            return
          }

          this.$refs.reportModal.openModal({
            userId: user.userId,
            username: user.username
          })
        }
        else {
          helpers.showOnbordingOrSignUp()
        }
      }
    }
  }
</script>

<style scoped>
  .swiper-slide {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    position: relative;
  }

  .swiper-wrapper {
    -webkit-transform-style: preserve-3d;
  }

  .tint.tinted:before {
    background: rgba(0, 0, 0, 0.65);
    @apply z-20 rounded-none
  }
</style>
