<!-- Unblock incoming photo in chat -->
<template>
  <div v-if="isEnoughMoney">
    <div class="flex items-center justify-between mb-1.5">
      <h2 class="h2">
        {{ $t('chat.attachments.purchase_content') }}
      </h2>

      <CloseCrossButton @click="emitClose"/>
    </div>

    <p class="mb-5">
      {{ $t('chat.attachments.confirm_purchase', { price: values.price }) }}
    </p>

    <AlertMessage
      v-if="!isLoading && successMessage"
      type="success"
      class="mb-3"
      :heading="successMessage"/>

    <button
      type="button"
      class="btn btn-block btn-primary mb-3 h-[46px]"
      :disabled="isLoading || isPending || successMessage"
      @click="confirmToUnlockPhoto">
      <Spinner v-show="isLoading"/>

      <span v-show="!isLoading">{{ $t('common.yes') }}</span>
    </button>

    <button
      type="button"
      class="btn btn-block btn-secondary"
      :disabled="isLoading"
      @click="emitClose">
      {{ $t('common.cancel') }}
    </button>
  </div>

  <Form
    v-else
    id="credits-form-1"
    v-slot="{ errors, setErrors }"
    :initial-values="initialValues"
    @submit="confirmToUnlockPhoto">
    <div class="mb-4">
      <h2 class="h2 mb-4">
        {{ $t('chat.attachments.purchase_content') }}
      </h2>

      <p class="mb-4" v-html="$t('chat.attachments.confirm_top_up', { cost: values?.attachment.price, price_in_currency: values?.attachment.priceInCurrency })"/>

      <div
        v-if="paymentMethods.length"
        class="border-t border-gray-200 py-2 mb-2 flex items-center whitespace-nowrap justify-between">
        <span class="text-gray-500">
          {{ $tc('user.your_cards.heading', 1) }}
        </span>

        <div class="flex justify-end items-center">
          <span class="ml-2 font-mono text-caption text-gray-500">
            <template v-if="paymentProcessor === 'stripe'">
              **** **** **** {{ activePaymentMethod.card.last4 }}
            </template>

            <template v-else-if="paymentProcessor === 'exactly'">
              {{ activePaymentMethod.card.maskedNumber }}
            </template>
          </span>
        </div>
      </div>

      <YupTooltip
        v-else
        :i18n="errors.terms">
        <div class="flex items-start mb-8">
          <div class="h-5 flex items-center">
            <Field
              id="post-terms"
              type="checkbox"
              name="terms"
              class="mt-1.5 h-4 w-4 text-primary border border-primary rounded focus:ring-blue-300"
              :value="true"
              :rules="rules.terms"
              @focus="setErrors(fieldsToReset)"/>
          </div>

          <div class="ml-3 text-sm">
            <label
              for="post-terms"
              class="font-medium text-gray-600 text-caption leading-4">
              <VRuntimeTemplate :template="$t('chat.attachments.terms_agreement', { terms_of_service: termsOfService, privacy_policy: privacyPolicy })"/>
            </label>
          </div>
        </div>
      </YupTooltip>

      <!-- States of action end -->
      <template v-if="!isLoading">
        <AlertMessage
          v-if="successMessage"
          type="success"
          class="mb-3"
          :heading="successMessage"/>

        <AlertMessage
          v-else-if="isFailed || (errorMessage && !isPending)"
          type="error"
          class="mb-3"
          :heading="errorMessage || 'Previous transaction failed'"
          :text="errorDescription"/>

        <AlertMessage
          v-else-if="isPending"
          type="warning"
          class="mb-3"
          heading="Transaction pending, check later or contact online support"/>

        <AlertMessage
          v-else-if="showWarnUpdatingCard && !isEnoughMoney"
          type="info"
          class="mb-3 -mt-4"
          heading="When making a payment, you will have to update your card details once more!"/>
      </template>

      <button
        type="submit"
        class="btn btn-block btn-primary mb-3 h-[46px]"
        :disabled="isLoading || isPending || successMessage">
        <Spinner v-show="isLoading"/>

        <span v-show="!isLoading">{{ $t('common.confirm') }}</span>
      </button>

      <button
        type="button"
        class="btn btn-block btn-secondary"
        :disabled="isLoading"
        @click="emitClose">
        {{ $t('common.cancel') }}
      </button>
    </div>
  </Form>
</template>

<script>

import CloseCrossButton from '@/components/ModalContents/parts/CloseCrossButton'
import { mapActions, mapState } from 'pinia'
import { useUserStore } from '@/stores/user'
import { useChatStore } from '@/stores/chat'
import { useAppStore } from '@/stores/app'
import { Field, Form } from 'vee-validate'
import YupTooltip from '@/components/Functional/YupTooltip'
import { usePaymentStore } from '@/stores/payment'
import Spinner from '@/components/Functional/Spinner.vue'
import AlertMessage from '@/components/Functional/AlertMessage.vue'
import uniqid from 'uniqid'
import app from '@/main'

export default {
  name: 'UnlockAttachment',
  components: {
    CloseCrossButton,
    YupTooltip,
    Field,
    Form,
    Spinner,
    AlertMessage
  },
  props: {
    values: {
      type: Object,
      required: true,
      default: () => ({
        userId: null
      })
    }
  },
  emits: ['close', 'unlocked'],
  data () {
    return {
      errorMessage: null,
      errorDescription: null,
      successMessage: null,
      isLoading: false,
      initialValues: {
        terms: false
      },
      fieldsToReset: {
        terms: false
      },
      rules: {
        terms: this.$yup.boolean()
          .required({ key: 'yup.custom.terms_and_conditions', values: {} })
          .oneOf([true], () => ({ key: 'yup.custom.terms_and_conditions', values: {} }))
      },
      initialBalance: ''
    }
  },
  computed: {
    ...mapState(useAppStore, [
      'currentTime',
      'endlessPaymentCreated'
    ]),
    ...mapState(useUserStore, [
      'balance',
      'userId',
      'premiumSupportAgentUserId'
    ]),
    ...mapState(useChatStore, [
      'chatId'
    ]),
    ...mapState(usePaymentStore, [
      'paymentProcessor',
      'activePaymentMethod',
      'paymentMethods',
      'attachmentPayments',
      'showWarnUpdatingCard'
    ]),
    isEnoughMoney () {
      return this.initialBalance >= this.values?.attachment.price
    },
    attachmentPayment () {
      return this.attachmentPayments.find(item => item.id.includes('attachment_' + this.values.messageId))
    },
    secondsLeftFromPayment () {
      if (this.attachmentPayment) {
        return this.currentTime - this.attachmentPayment.createdAt
      }
      else {
        return null
      }
    },
    isPending () {
      return this.attachmentPayment && ['created', 'payment_pending', 'payment_accepted'].includes(this.attachmentPayment.status)
    },
    isFailed () {
      return this.attachmentPayment && ['failed', 'cancelled'].includes(this.attachmentPayment.status)
    },
    termsOfService () {
      return `<a
                href="https://one2fan.com/terms"
                target="_blank"
                class="text-primary">
                {{ $t("common.terms_of_service") }}
              </a>`
    },
    privacyPolicy () {
      return `<a
                href="https://one2fan.com/policy"
                target="_blank"
                class="text-primary">
                {{ $t('common.privacy_policy') }}
              </a>`
    }
  },
  watch: {
    async attachmentPayment (payment) {
      if (payment?.status === 'succeeded') {
        this.purchaseDelete(payment.purchaseId)
        this.successMessage = 'Payment successful'
        await new Promise(resolve => setTimeout(resolve, 1000))
        const message = await this.messageGet()

        setTimeout(() => {
          this.$emit('unlocked', message)
          this.emitClose()
        }, 1000)
      }
      else if (['failed', 'cancelled'].includes(payment?.status)) {
        this.errorMessage = payment?.error || 'Payment failed'
        this.purchaseDelete(payment.purchaseId)
      }
    }
  },
  async mounted () {
    if (this.attachmentPayment) {
      const purchaseId = this.attachmentPayment.purchaseId
      await this.purchaseGet(purchaseId)

      if (this.attachmentPayment.status === 'created') {
        await this.purchaseCancel(purchaseId)
          .then(() => {
            this.purchaseDelete(purchaseId)
          })
      }
    }
  },
  beforeMount () {
    this.initialBalance = this.balance
  },
  methods: {
    ...mapActions(usePaymentStore, [
      'paymentMethodsGet',
      'checkoutSessionCreate',
      'messageUnlockAttachment',
      'purchaseGet',
      'purchaseCreate',
      'purchaseDelete',
      'purchaseCancel'
    ]),
    ...mapActions(useUserStore, [
      'meGet'
    ]),
    async emitClose () {
      await this.$emit('close')
    },
    async messageGet () {
      const res = await app.wsp
        .sendRequest({
          data: {
            senderUserId: this.chatId,
            receiverUserId: null,
            messageId: this.values.messageId.toString()
          },
          method: 'message.get'
        })

      if (res.error || !res.data?.message) {
        return
      }

      return res.data?.message
    },
    async confirmToUnlockPhoto () {
      if (this.isLoading) {
        return
      }

      this.errorMessage = null
      this.errorDescription = null
      this.successMessage = null

      this.isLoading = true

      // ID of the user who sent the attachment. This parameter is mandatory
      // when the attachment is incoming, otherwise this parameter must be omitted.
      const data = {
        senderUserId: this.chatId,
        receiverUserId: null,
        messageId: this.values.messageId.toString(),
        cost: this.values?.attachment.price
      }

      // Update balance
      await this.meGet()

      if (!this.isEnoughMoney) {
        data.purchaseCreditsIfNotEnough = true

        // Re-check payments methods
        if (!this.activePaymentMethod.id) {
          await this.paymentMethodsGet()
        }
      }

      if (!this.isEnoughMoney && this.activePaymentMethod.id) {
        data.paymentMethodId = this.activePaymentMethod.id
      } else {
        // If no money no card: buy credits and buy immediately (See TheChat's unlockAttachment method)
        if (!this.isEnoughMoney) {
          const chatId = this.chatId
          const messageId = this.values.messageId
          const cost = this.values?.attachment.price

          await this.checkoutSessionCreate({
            successUrl: `${window.origin}/chat/${chatId}?senderUserId=${chatId}&receiverUserId=&messageId=${messageId}&cost=${cost}`,
            cancelUrl: `${window.origin}/chat/${chatId}`,
            savePaymentMethod: true,
            productType: 'credits',
            productId: cost
          })

          return
        }
      }

      if (!this.isEnoughMoney) {
        data.purchaseCreditsIfNotEnough = true
        data.paymentMethodId = this.activePaymentMethod.id

        // Check payment status or init one
        if (this.attachmentPayment) {
          await this.purchaseGet(this.attachmentPayment.purchaseId)
        }
        else {
          await this.purchaseCreate({ id: `attachment_${this.values.messageId}_${uniqid()}` })
        }

        if (this.endlessPaymentCreated) {
          await new Promise(resolve => setTimeout(resolve, 1000 * 5))
          this.isLoading = false
          return
        }

        // Remove failed purchase if payment initialized again
        if (['failed', 'cancelled'].includes(this.attachmentPayment?.status)) {
          this.purchaseDelete(this.attachmentPayment.purchaseId)
        }

        if (this.attachmentPayment?.purchaseId) {
          data.purchaseId = this.attachmentPayment.purchaseId
        }
      }

      await this.messageUnlockAttachment(data)
        .then(async (res) => {
          this.errorMessage = null
          this.errorDescription = null
          this.successMessage = 'Payment successful'

          if (res.data?.message) {
            this.isLoading = false

            setTimeout(() => {
              this.$emit('unlocked', res.data.message)
              this.emitClose()
            }, 1500)
          }
        })
        .catch((error) => {
          if (error?.code === 147) {
            this.errorMessage = error?.message
            this.errorDescription = error?.description
          }
          // Payment is pending
          else if (error?.code === 149) {
            //
          }
          else {
            this.errorMessage = 'Error occured'
          }

          // Remove payment
          if ([147, 105].includes(error?.code)) {
            this.purchaseDelete(this.attachmentPayment.purchaseId)
          }
        })
        .finally(async () => {
          if (this.attachmentPayment) {
            await this.purchaseGet(this.attachmentPayment.purchaseId)

            // If purchase not completed -> wait n seconds to show loading
            if (!['succeeded', 'failed', 'cancelled'].includes(this.attachmentPayment?.status)) {
              await new Promise(resolve => setTimeout(resolve, 1000 * 10))
            }
          }

          this.isLoading = false
        })
    }
  }
}
</script>
