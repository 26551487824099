<script setup>
  import helpers from '@/helpers'

  const track1 = () => {
    helpers.addTextScript('twq(\'event\', \'Landing Page View\', {});')
  }
  const track2 = () => {
    helpers.addTextScript('twq(\'event\', \'landing-page-view\', {});')
  }
  const track3 = () => {
    helpers.addTextScript('twq(\'event\', \'landing page view\', {});')
  }
</script>

<template>
  <div class="p-10 flex gap-3">
    <button class="btn" @click="track1">track1 "Landing Page View"</button>
    <button class="btn" @click="track2">track2 "landing-page-view"</button>
    <button class="btn" @click="track3">track3 "landing page view"</button>
  </div>
</template>