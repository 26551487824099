<!-- Buy and send photo from logged user -->
<template>
  <div v-if="isEnoughMoney">
    <div class="flex items-center justify-between mb-5">
      <h2 class="h2">
        <template v-if="values.attachment.mediaType === 'image'">
          {{ $t('chat.attachments.send_a_photo') }}
        </template>

        <template v-else>
          {{ $t('chat.attachments.send_a_video') }}
        </template>
      </h2>

      <button
        type="button"
        class="btn py-2 pl-3 pr-0 cursor-pointer"
        @click="emitClose">
        <svg
          width="12"
          height="12"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M1.78033 0.46967C1.48744 0.176777 1.01256 0.176777 0.71967 0.46967C0.426777 0.762563 0.426777 1.23744 0.71967 1.53033L4.18934 5L0.71967 8.46967C0.426777 8.76256 0.426777 9.23744 0.71967 9.53033C1.01256 9.82322 1.48744 9.82322 1.78033 9.53033L5.25 6.06066L8.71967 9.53033C9.01256 9.82322 9.48744 9.82322 9.78033 9.53033C10.0732 9.23744 10.0732 8.76256 9.78033 8.46967L6.31066 5L9.78033 1.53033C10.0732 1.23744 10.0732 0.762563 9.78033 0.46967C9.48744 0.176777 9.01256 0.176777 8.71967 0.46967L5.25 3.93934L1.78033 0.46967Z" fill="#161722"/>
        </svg>
      </button>
    </div>

    <p class="mb-5">
      {{ $t('chat.attachments.credits_will_be_spent', { cost: values.attachment.price }) }}
    </p>

    <AlertMessage
      v-if="!isLoading && successMessage"
      type="success"
      class="mb-3"
      :heading="successMessage"/>

    <div>
      <button
        type="button"
        class="btn btn-block btn-primary mb-3 h-[46px]"
        :disabled="isLoading || isPending || successMessage"
        @click="confirmToUnlockPhoto">
        <Spinner v-show="isLoading"/>

        <span v-show="!isLoading">{{ $t('common.confirm') }}</span>
      </button>

      <button
        type="button"
        class="btn btn-block btn-secondary"
        :disabled="isLoading"
        @click="emitClose">
        {{ $t('common.cancel') }}
      </button>
    </div>
  </div>

  <Form
    v-else
    id="credits-form-1"
    v-slot="{ errors, setErrors }"
    :initial-values="initialValues"
    @submit="confirmToUnlockPhoto">
    <div class="mb-4">
      <h2 class="h2 mb-4">
        {{ $t('chat.attachments.purchase_content') }}
      </h2>

      <p class="mb-4" v-html="$t('chat.attachments.confirm_top_up', { cost: values.attachment.price, price_in_currency: values.attachment.priceInCurrency })"/>

      <div
        v-if="paymentMethods.length"
        class="border-t border-gray-200 py-2 mb-2 items-center whitespace-nowrap flex justify-between">
        <span class="text-gray-500">
          {{ $tc('user.your_cards.heading', 1) }}
        </span>

        <div class="flex justify-end items-center">
          <span class="ml-2 font-mono text-caption text-gray-500">
            <template v-if="paymentProcessor === 'stripe'">
              **** **** **** {{ activePaymentMethod.card.last4 }}
            </template>

            <template v-else-if="paymentProcessor === 'exactly'">
              {{ activePaymentMethod.card.maskedNumber }}
            </template>
          </span>
        </div>
      </div>

      <YupTooltip
        v-else
        :i18n="errors.terms">
        <div class="flex items-start mb-8">
          <div class="h-5 flex items-center">
            <Field
              id="post-terms"
              type="checkbox"
              name="terms"
              class="mt-1.5 h-4 w-4 text-primary border border-primary rounded focus:ring-blue-300"
              :value="true"
              :rules="rules.terms"
              @focus="setErrors(fieldsToReset)"/>
          </div>

          <div class="ml-3 text-sm">
            <label
              for="post-terms"
              class="font-medium text-gray-600 text-caption leading-4">
              <VRuntimeTemplate :template="$t('chat.attachments.terms_agreement', { terms_of_service: termsOfService, privacy_policy: privacyPolicy })"/>
            </label>
          </div>
        </div>
      </YupTooltip>

      <!-- States of action end -->
      <template v-if="!isLoading">
        <AlertMessage
          v-if="successMessage"
          type="success"
          class="mb-3"
          :heading="successMessage"/>

        <AlertMessage
          v-else-if="isFailed || (errorMessage && !isPending)"
          type="error"
          class="mb-3"
          :heading="errorMessage || 'Previous transaction failed'"
          :text="errorDescription"/>

        <AlertMessage
          v-else-if="isPending"
          type="warning"
          class="mb-3"
          heading="Transaction pending, check later or contact online support"/>

        <AlertMessage
          v-else-if="showWarnUpdatingCard && !isEnoughMoney"
          type="info"
          class="mb-3 -mt-4"
          heading="When making a payment, you will have to update your card details once more!"/>
      </template>

      <button
        type="submit"
        class="btn btn-block btn-primary mb-3 h-[46px]"
        :disabled="isLoading || isPending || successMessage">
        <Spinner v-show="isLoading"/>

        <span v-show="!isLoading">{{ $t('common.confirm') }}</span>
      </button>

      <button
        type="button"
        class="btn btn-block btn-secondary"
        :disabled="isLoading"
        @click="emitClose">
        {{ $t('common.cancel') }}
      </button>
    </div>
  </Form>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useChatStore } from '@/stores/chat'
import { useUserStore } from '@/stores/user'
import { useAppStore } from '@/stores/app'
import { Field, Form } from 'vee-validate'
import YupTooltip from '@/components/Functional/YupTooltip'
import { usePaymentStore } from '@/stores/payment'
import AlertMessage from '@/components/Functional/AlertMessage.vue'
import Spinner from '@/components/Functional/Spinner.vue'
import uniqid from 'uniqid'
import app from '@/main'

// Purchase photo that user sending
export default {
  name: 'SendPhotoContent',
  components: {
    YupTooltip,
    Field,
    Form,
    AlertMessage,
    Spinner
  },
  props: {
    values: {
      type: Object,
      required: true,
      default: () => ({
        userId: null
      })
    }
  },
  emits: ['get-more-credits', 'close', 'unlocked'],
  data () {
    return {
      successMessage: null,
      errorMessage: null,
      errorDescription: null,
      isLoading: false,
      initialValues: {
        terms: false
      },
      fieldsToReset: {
        terms: false
      },
      rules: {
        terms: this.$yup.boolean()
          .required({ key: 'yup.custom.terms_and_conditions', values: {} })
          .oneOf([true], () => ({ key: 'yup.custom.terms_and_conditions', values: {} }))
      },
      initialBalance: ''
    }
  },
  computed: {
    ...mapState(useAppStore, [
      'currentTime',
      'endlessPaymentCreated'
    ]),
    ...mapState(useChatStore, [
      'chatId',
      'messageToBuy'
    ]),
    ...mapState(useUserStore, [
      'balance',
      'userId',
      'premiumSupportAgentUserId'
    ]),
    ...mapState(usePaymentStore, [
      'paymentProcessor',
      'activePaymentMethod',
      'paymentMethods',
      'attachmentPayments',
      'showWarnUpdatingCard'
    ]),
    isEnoughMoney () {
      return this.initialBalance >= this.values.attachment.price
    },
    attachmentPayment () {
      return this.attachmentPayments.find(item => item.id.includes('attachment_' + this.values.messageId))
    },
    secondsLeftFromPayment () {
      if (this.attachmentPayment) {
        return this.currentTime - this.attachmentPayment.createdAt
      }
      else {
        return null
      }
    },
    isPending () {
      return this.attachmentPayment && this.attachmentPayment && ['created', 'payment_pending', 'payment_accepted'].includes(this.attachmentPayment.status)
    },
    isFailed () {
      return this.attachmentPayment && ['failed', 'cancelled'].includes(this.attachmentPayment.status)
    },
    termsOfService () {
      return `<a
                href="https://one2fan.com/terms"
                target="_blank"
                class="text-primary">
                {{ $t("common.terms_of_service") }}
              </a>`
    },
    privacyPolicy () {
      return `<a
                href="https://one2fan.com/policy"
                target="_blank"
                class="text-primary">
                {{ $t('common.privacy_policy') }}
              </a>`
    }
  },
  watch: {
    async attachmentPayment (payment) {
      if (payment?.status === 'succeeded') {
        this.purchaseDelete(payment.purchaseId)
        this.successMessage = 'Payment successful'
        await new Promise(resolve => setTimeout(resolve, 1000))
        const message = await this.messageGet()

        setTimeout(() => {
          this.$emit('unlocked', message)
          this.emitClose()
        }, 1000)
      }
      else if (['failed', 'cancelled'].includes(payment?.status)) {
        this.errorMessage = payment?.error || 'Payment failed'
        this.purchaseDelete(payment.purchaseId)
      }
    }
  },
  async mounted () {
    this.initialBalance = this.balance

    if (this.attachmentPayment) {
      const purchaseId = this.attachmentPayment.purchaseId
      await this.purchaseGet(purchaseId)

      if (this.attachmentPayment.status === 'created') {
        await this.purchaseCancel(purchaseId)
          .then(() => {
            this.purchaseDelete(purchaseId)
          })
      }
    }
  },
  methods: {
    ...mapActions(usePaymentStore, [
      'paymentMethodsGet',
      'checkoutSessionCreate',
      'messageUnlockAttachment',
      'purchaseGet',
      'purchaseCreate',
      'purchaseDelete',
      'purchaseCancel'
    ]),
    ...mapActions(useUserStore, [
      'meGet'
    ]),
    async emitClose () {
      await this.$emit('close')
    },
    async messageGet () {
      const res = await app.wsp
        .sendRequest({
          data: {
            senderUserId: null,
            receiverUserId: this.chatId,
            messageId: this.values.messageId.toString()
          },
          method: 'message.get'
        })

      if (res.error || !res.data?.message) {
        return
      }

      return res.data?.message
    },
    async confirmToUnlockPhoto () {
      if (this.isLoading) {
        return
      }

      this.errorMessage = null
      this.errorDescription = null
      this.successMessage = null

      this.isLoading = true

      // ID of the user who sent the attachment. This parameter is mandatory
      // when the attachment is incoming, otherwise this parameter must be omitted.
      const data = {
        senderUserId: null,
        receiverUserId: this.chatId,
        messageId: this.values.messageId.toString(),
        cost: this.values.attachment.price
      }

      // Update balance
      await this.meGet()

      if (!this.isEnoughMoney) {
        data.purchaseCreditsIfNotEnough = true

        // Re-check payments methods
        if (!this.activePaymentMethod.id) {
          await this.paymentMethodsGet()
        }
      }

      if (!this.isEnoughMoney && this.activePaymentMethod.id) {
        data.paymentMethodId = this.activePaymentMethod.id
      } else {
        // If no money no card: buy credits and buy immediately (See TheChat's unlockAttachment method)
        if (!this.isEnoughMoney) {
          const chatId = this.chatId
          const messageId = this.values.messageId
          const cost = this.values.attachment.price

          await this.checkoutSessionCreate({
            successUrl: `${window.origin}/chat/${chatId}?senderUserId=&receiverUserId=${chatId}&messageId=${messageId}&cost=${cost}`,
            cancelUrl: `${window.origin}/chat/${chatId}`,
            savePaymentMethod: true,
            productType: 'credits',
            productId: cost
          })

          return
        }
      }

      if (!this.isEnoughMoney) {
        // Check payment status or init one
        if (this.attachmentPayment) {
          await this.purchaseGet(this.attachmentPayment.purchaseId)
        }
        else {
          await this.purchaseCreate({ id: `attachment_${this.values.messageId}_${uniqid()}` })
        }

        if (this.endlessPaymentCreated) {
          await new Promise(resolve => setTimeout(resolve, 1000 * 5))
          this.isLoading = false
          return
        }

        // Remove failed purchase if payment initialized again
        if (['failed', 'cancelled'].includes(this.attachmentPayment?.status)) {
          this.purchaseDelete(this.attachmentPayment.purchaseId)
        }

        if (this.attachmentPayment?.purchaseId) {
          data.purchaseId = this.attachmentPayment.purchaseId
        }

        data.purchaseCreditsIfNotEnough = true
        data.paymentMethodId = this.activePaymentMethod.id
      }

      await this.messageUnlockAttachment(data)
        .then(async (res) => {
          this.errorMessage = null
          this.errorDescription = null
          this.successMessage = 'Payment successful'

          if (res.data?.message) {
            this.isLoading = false

            setTimeout(() => {
              this.$emit('unlocked', res.data?.message)
              this.$emit('close')
            }, 1500)
          }
        })
        .catch((error) => {
          if (error?.code === 147) {
            this.errorMessage = error?.message
            this.errorDescription = error?.description
          }
          // Payment is pending
          else if (error?.code === 149) {
            //
          }
          else {
            this.errorMessage = 'Error occured'
          }

          // Remove payment
          if ([147, 105].includes(error?.code)) {
            this.purchaseDelete(this.attachmentPayment.purchaseId)
          }
        })
        .finally(async () => {
          if (this.attachmentPayment) {
            await this.purchaseGet(this.attachmentPayment.purchaseId)

            // If purchase not completed -> wait n seconds to show loading
            if (!['succeeded', 'failed', 'cancelled'].includes(this.attachmentPayment?.status)) {
              await new Promise(resolve => setTimeout(resolve, 1000 * 10))
            }
          }

          this.isLoading = false
        })
    }
  }
}
</script>
