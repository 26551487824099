import { defineStore } from 'pinia'
import app from '@/main'
import { useAuthStore } from './auth'

export const useCreatorsCatalogStore = defineStore({
  id: 'creators_catalog',
  state: () => ({
    catalogItems: [],
    searchQuesryStr: '',
    // Loading states: loaded, loading, loading-more
    loadingStatus: '',
    viewedPostIds: [],
    searchInputFocused: false,
    searchFormOpen: false,
    loadMoreCounter: 0,
    sexFilter: 2, // 1 or 2
    acitiveTab: null
  }),
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
        paths: [
          'sexFilter'
        ]
      }
    ]
  },
  getters: {
    isCatalogLoading: (state) => {
      return ['loading', 'loading-more', 'typing'].includes(state.loadingStatus)
    }
  },
  actions: {
    /*
    * Get feed items
    * Docs: https://github.com/wiggumlab/fansy-server/blob/next/doc/API/methods/users.feed.md
    * */
    async usersFeed ({ type = 'init', limit = 14 }) {
      this.loadingStatus = type === 'init' ? 'loading' : 'loading-more'

      // Данное API предоставляет возможность постраничного прохода по множеству элементов с явным указанием
      // как уже просмотренных (т.е. исключаемых из текущего и последующих запросов), так и загруженных,
      // но не просмотренных (т.е. исключаемых только из текущего запроса), без какого-либо представления о том,
      // как именно эти элементы отображаются пользователю.

      // В твоем случае ты можешь просто не использовать excludedUserIds,
      // т.к. при запросе следующей страницы все элементы текущей считаются просмотренными.

      // Только, как я говорил ранее, если пользователь не аутентифицирован,
      // тебе придется использовать только exludedUserIds для обеспечения уникальности элементов выдачи.

      const authStore = useAuthStore()

      const viewedUserIds = this.catalogItems.map(item => item.userId)

      const res = await app.wsp
        .sendRequest({
          method: 'users.feed',
          data: {
            isCreator: true,
            limit: limit,
            viewedUserIds: authStore.isAuthenticated ? viewedUserIds : [],
            excludedUserIds: viewedUserIds,
            // sex: this.sexFilter,
            creatorCategory: authStore.options?.activeCreatorCategories ? this.acitiveTab : null
          }
        }).finally(() => {
          this.loadingStatus = 'loaded'
        })

      if (res.data?.users) {
        if (type === 'init') {
          this.catalogItems = res.data.users
        } else {
          this.catalogItems.push(...res.data.users)
        }

        if (res.data.users.length === 0) {
          this.loadingStatus = 'loaded-all'
        }
      }
    },
    /*
    * Search users
    * Docs: https://github.com/wiggumlab/fansy-server/blob/next/doc/API/methods/users.search.md
    * */
    async usersSearch ({ type, limit = 10 }) {
      this.loadingStatus = type === 'init' ? 'loading' : 'loading-more'
      const offset = type === 'init' ? 0 : this.catalogItems.length

      const res = await app.wsp
        .sendRequest({
          method: 'users.search',
          data: {
            query: this.searchQuesryStr,
            isCreator: true,
            limit: limit,
            offset: offset
          }
        }).finally(() => {
          this.loadingStatus = 'loaded'
        })

      if (res.data?.users) {
        if (type === 'init') {
          this.catalogItems = res.data.users
        } else {
          this.catalogItems.push(...res.data.users)
        }
      }

      if (res.data.users.length === 0 || res.data.users.length < limit) {
        this.loadingStatus = 'loaded-all'
      }
    }
  }
})
