<template>
  <div class="flex flex-col justify-between items-center">
    <div/>

    <div class="flex flex-col items-center text-center">
      <img
        class="w-28 mb-5"
        src="@/assets/images/emoji/camera.png"
        alt="Photo emoji">

      <h4 class="font-semibold h2 mb-1">
        {{ $t('chat.attachments.you_will_see_all_media') }}
      </h4>

      <router-link
        v-if="name && username"
        class="cursor-default"
        :to="'/@' + username">
        <span v-html="$t('chat.attachments.no_attachments', { name: `<b class='font-normal cursor-pointer'>@${username}</b>` })"/>
      </router-link>
    </div>

    <router-link
      :to="`/chat/${$route.params.chatId}`"
      class="btn btn-block btn-primary"
      type="button">
      {{ $t('chat.attachments.start_chatting') }}
    </router-link>
  </div>
</template>

<script>
import { getUser } from '@/helpers/methods'

export default {
  name: 'ChatAttachmentsNoItems',
  components: {},
  data () {
    return {
      name: null,
      username: null
    }
  },
  computed: {},
  async mounted () {
    const chatId = this.$route.params.chatId

    if (!chatId) {
      return
    }

    if (chatId) {
      const { data } = await getUser({ id: chatId })

      if (data?.user) {
        this.name = data?.user.name
        this.username = data?.user.username
      }
    }
  },
  methods: {}
}
</script>

