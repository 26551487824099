import { defineStore } from 'pinia'
import app from '@/main'
import { set } from 'lodash'
import dayjs from 'dayjs'
import helpers from '@/helpers'

export const usePaymentStore = defineStore({
  id: 'payment',
  state: () => ({
    selectedProduct: {
      id: null
    },
    // Path to redirect back after credits purchase
    pathBeforeBuying: null,
    // If user manually select payment method, see activePaymentMethod getter
    selectedPaymentMethod: { id: null },
    paymentMethods: [],
    oneClickPurchaseLoading: false,
    previousPaymentProcessor: '',
    // Show warning: "When making a payment, you will have to update your card details once more!"
    showWarnUpdatingCard: false,
    selectedPaymentProcessor: '',
    options: {
      purchaseConfig: {
        // Payment processor that should be used. Supported values: 'stripe', 'exactly'
        paymentProcessorId: '',
        currency: 'USD',
        products: {
          credits: []
        }
      },
      trialCreditsForBankCard: {
        creditCount: null,
        credited: null
      }
    },
    /*
    * ID of purchases { id, purchaseId, status }
    * status: created | succeeded | failed | cancelled
    * */
    payments: []
  }),
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
        paths: [
          'selectedProduct',
          'pathBeforeBuying',
          'selectedPaymentMethod',
          'selectedPaymentProcessor',
          'payments',
          'previousPaymentProcessor',
          'userEnteredCard',
          'showWarnUpdatingCard',
          'options'
        ]
      }
    ]
  },
  getters: {
    // stripe | exactly
    paymentProcessor: (state) => state.selectedPaymentProcessor || state.options.purchaseConfig.paymentProcessorId,
    products: (state) => state.options.purchaseConfig.products.credits,
    currency: (state) => state.options.purchaseConfig.currency,
    trialCreditsForBankCard: (state) => state.options.trialCreditsForBankCard,
    activePaymentMethod: (state) => {
      if (state.selectedPaymentMethod.id) {
        return state.selectedPaymentMethod
      }
      else {
        return state.paymentMethods.length ? state.paymentMethods[0] : { id: null }
      }
    },
    tipsPayment: (state) => state.payments.find(item => item.id.includes('tips_')),
    creditsPayment: (state) => state.payments.find(item => item.id.includes('credits_')),
    subscriptionPayments: (state) => state.payments.filter(item => item.id.includes('subscription_')),
    postPayments: (state) => state.payments.filter(item => item.id.includes('post_')),
    attachmentPayments: (state) => state.payments.filter(item => item.id.includes('attachment_'))
  },
  actions: {
    /*
    * Get all payment methods.
    * Stripe: https://github.com/wiggumlab/fansy-server/blob/next/doc/API/methods/stripe.paymentMethods.get.md
    * Exactly: https://github.com/wiggumlab/fansy-server/blob/next/doc/API/methods/exactly.paymentMethods.get.md
    * */
    async paymentMethodsGet () {
      const res = await app.wsp.sendRequest({
        data: {},
        method: `${this.paymentProcessor}.paymentMethods.get`
      })

      if (res.error) return

      this.paymentMethods = res.data.paymentMethods
    },
    // Update payment methods when payment processor changed
    async resetPaymentMethods () {
      this.selectedPaymentMethod = { id: null }

      await this.paymentMethodsGet()

      this.checkPaymentProcessorUpdate()
    },
    /*
    * Delete a payment method.
    * Stripe: https://github.com/wiggumlab/fansy-server/blob/next/doc/API/methods/stripe.paymentMethod.delete.md
    * Exactly: https://github.com/wiggumlab/fansy-server/blob/next/doc/API/methods/exactly.paymentMethod.delete.md
    * */
    async paymentMethodDelete (id) {
      const res = await app.wsp.sendRequest({
        data: {
          id: id
        },
        method: `${this.paymentProcessor}.paymentMethod.delete`
      })

      return res.data
    },
    /*
    * Create a checkout session. This method allows using Exactly-hosted or Stripe-hosted payment page
    * to purchase a product, and optionally save the payment method being used (e.g. bank card) for future purchases
    * (when savePaymentMethod is set to true, stripe only).
    *
    * Stripe: https://github.com/wiggumlab/fansy-server/blob/next/doc/API/methods/stripe.checkoutSession.create.md
    * Exactly: https://github.com/wiggumlab/fansy-server/blob/next/doc/API/methods/exactly.checkoutSession.create.md
    * */
    async checkoutSessionCreate ({
      productType,
      productId,
      successUrl,
      cancelUrl,
      savePaymentMethod
    }) {
      const data = {
        productType,
        productId,
        successUrl,
        savePaymentMethod
      }

      const cancelUrlKeyNames = new Map([
        ['stripe', 'cancelUrl'],
        ['exactly', 'failureUrl']
      ])

      // Choose relevant key name for API reject URL
      set(data, cancelUrlKeyNames.get(this.paymentProcessor), cancelUrl)

      const res = await app.wsp.sendRequest({
        data: data,
        method: `${this.paymentProcessor}.checkoutSession.create`
      })

      if (res.error && productType === 'subscription' && res.error.code === 105) {
        await this.subscriptionRestore({
          publisherUserId: productId,
          successUrl: successUrl
        })

        return
      }

      if (res.error) return

      if (res.data?.url) {
        this.router.push({ redirect: window.location.href = res.data.url })
      }
    },
    /*
    * В запросы `exactly.purchase`, `stripe.purchase`, `message.unlockAttachment`, `post.purchase`
    * добавлен параметр `purchaseId: ?string`.
    * При каждой покупке через вышеперечисленные методы клиент
    * должен сначала получить идентификатор покупки от сервера, вызвав метод `purchase.create`, сохранить
    * этот идентификатор в постоянное локальное хранилище, и после этого передавать его в запросе в параметре
    * `purchaseId` при всех попытках совершения этой покупки.
    *
    * https://github.com/wiggumlab/fansy-server/blob/3f7e4b544e4af80a48c67cf3fec8d62a0a9d4a04/doc/API/methods/purchase.create.md
    * */
    async purchaseCreate ({ id }) {
      console.log('PURCHASE CREATE')

      const { data } = await app.wsp.sendRequest({
        data: null,
        method: 'purchase.create'
      })

      if (data) {
        const payment = {
          purchaseId: data?.id,
          id: id,
          status: 'created',
          createdAt: dayjs().unix()
        }

        this.payments.push(payment)

        return payment
      }
    },
    /*
    * Get status of purchase
    * https://github.com/wiggumlab/fansy-server/blob/3f7e4b544e4af80a48c67cf3fec8d62a0a9d4a04/doc/API/methods/purchase.get.md
    * Response: https://github.com/wiggumlab/fansy-server/blob/3f7e4b544e4af80a48c67cf3fec8d62a0a9d4a04/doc/API/objects/Purchase.md
    * */
    async purchaseGet (purchaseId) {
      const { data } = await app.wsp.sendRequest({
        data: {
          id: purchaseId
        },
        method: 'purchase.get'
      })

      // Update status of payment
      if (data) {
        const index = this.payments.findIndex((item) => item.purchaseId === purchaseId)

        if (index >= 0) {
          // Remove payment if transaction successful
          if (data?.purchase.status === 'succeeded') {
            this.payments.splice(index, 1)
          }
          else {
            this.payments[index] = {
              purchaseId: data?.purchase.id,
              id: this.payments[index].id,
              status: data?.purchase.status,
              createdAt: data?.purchase.createdAt
            }
          }
        }
      }
    },
    purchaseDelete (purchaseId) {
      const index = this.payments.findIndex((item) => item.purchaseId === purchaseId)

      if (index >= 0) {
        this.payments.splice(index, 1)
      }
    },
    async purchaseCancel (purchaseId) {
      return app.wsp.sendRequest({
        data: {
          id: purchaseId
        },
        method: 'purchase.cancel'
      })
    },
    /*
    * Purchase a product via Exactly or Stripe.
    * Currently, this method requires a saved Exactly payment method (For Exactly).
    * To purchase a product without a saved payment method, and optionally save the payment method being used,
    * use exactly.checkoutSession.create method
    *
    * Stipe: https://github.com/wiggumlab/fansy-server/blob/next/doc/API/methods/stripe.purchase.md
    * Exactly: https://github.com/wiggumlab/fansy-server/blob/next/doc/API/methods/exactly.purchase.md
    * */
    async purchase ({
      productType,
      productId,
      paymentMethodId,
      successUrl,
      purchaseId = null
    }) {
      const res = await app.wsp.sendRequest({
        data: {
          productType,
          productId,
          paymentMethodId,
          id: purchaseId,
          paymentProcessorId: this.paymentProcessor
        },
        method: 'purchase.make'
      })

      if (res.error && productType === 'subscription' && res.error.code === 105) {
        const res = await this.subscriptionRestore({
          publisherUserId: productId,
          successUrl: successUrl
        })

        if (res.error) {
          return Promise.reject(res.error)
        }

        return
      }

      if (res.error) {
        return Promise.reject(res.error)
      }

      // if (productType === 'subscription') {
      //   helpers.addTextScript('twq(\'event\', \'tw-of10d-olx1h\', {});')
      // }
      // else {
      //   helpers.addTextScript(`twq('event', 'tw-of10d-olx0p', { value: ${null} });`)
      // }

      await this.purchaseGet(purchaseId)

      if (successUrl) {
        setTimeout(() => {
          this.router.push({ redirect: window.location.href = successUrl })
        }, 100)
      }
    },
    /*
    * Restore subscription if status is 105
    * https://github.com/wiggumlab/fansy-server/blob/next/doc/API/methods/subscription.restore.md
    * */
    async subscriptionRestore ({ publisherUserId, successUrl }) {
      await app.wsp.sendRequest({
        data: {
          publisherUserId
        },
        method: 'subscription.restore'
      })

      setTimeout(() => {
        this.router.push({ redirect: window.location.href = successUrl })
      }, 100)
    },
    async messageUnlockAttachment ({
      senderUserId = null,
      receiverUserId = null,
      messageId,
      purchaseCreditsIfNotEnough = null,
      paymentMethodId = null,
      purchaseId = null,
      cost = null
    }) {
      const data = {
        messageId: messageId
      }

      // senderUserId: id of the user who sent the attachment. This parameter is mandatory
      // when the attachment is incoming, otherwise this parameter must be omitted.

      // receiverUserId: id of the user to whom the attachment was sent. This parameter is mandatory
      // when the attachment is outgoing, otherwise this parameter must be omitted.

      purchaseId && set(data, 'purchaseId', purchaseId)
      senderUserId && set(data, 'senderUserId', senderUserId)
      receiverUserId && set(data, 'receiverUserId', receiverUserId)
      purchaseCreditsIfNotEnough && set(data, 'purchaseCreditsIfNotEnough', purchaseCreditsIfNotEnough)

      if (paymentMethodId) {
        data.paymentProcessorId = this.paymentProcessor
        data.paymentMethodId = paymentMethodId
      }

      const res = await app.wsp.sendRequest({
        data: data,
        method: 'message.unlockAttachment'
      })

      if (res.error) {
        return Promise.reject(res.error)
      }

      helpers.addTextScript(`twq('event', 'tw-of10d-olx0p', { value: ${cost} });`)

      return res
    },
    /*
    * Unlock post
    * https://github.com/wiggumlab/fansy-server/blob/next/doc/API/methods/post.purchase.md
    * */
    async postPurchase ({
      userId = null,
      number = null,
      purchaseId = null,
      purchaseCreditsIfNotEnough = null,
      paymentMethodId = null,
      cost = null
    }) {
      const data = {
        userId: String(userId),
        number: number
      }

      purchaseCreditsIfNotEnough && set(data, 'purchaseCreditsIfNotEnough', purchaseCreditsIfNotEnough)
      purchaseId && set(data, 'purchaseId', purchaseId)

      if (paymentMethodId) {
        data.paymentProcessorId = this.paymentProcessor
        data.paymentMethodId = paymentMethodId
      }

      const res = await app.wsp.sendRequest({
        data: data,
        method: 'post.purchase'
      })

      if (res.error) {
        return Promise.reject(res.error)
      }

      helpers.addTextScript(`twq('event', 'tw-of10d-olx0p', { value: ${cost} });`)

      return res.data
    },
    // Resend unpaiyed chat message
    async messageResend (messageId, chatId) {
      const res = await app.wsp.sendRequest({
        data: {
          receiverUserId: chatId,
          id: messageId
        },
        method: 'message.resend'
      })

      if (res.error) {
        console.log(res.error.message)
      }
    },
    /*
    * WS Response
    * https://github.com/wiggumlab/fansy-server/blob/next/doc/API/objects/SessionOptions.md
    * */
    async sessionStart (response) {
      if (!response.error) {
        this.options = response.data.options

        // console.log(response.data.options.purchaseConfig.products.credits)


        // Set Exactly payments by default
        if (!this.options.purchaseConfig.paymentProcessorId && !this.selectedPaymentProcessor) {
          this.selectedPaymentProcessor = 'exactly'
        }

        // Select tariff if no one was selected
        if (this.products.length) {
          if (!this.selectedProduct.id) {
            this.selectedProduct = this.products[0]
          }
        }

        await this.paymentMethodsGet()
        this.checkPaymentProcessorUpdate()
      }
    },
    checkPaymentProcessorUpdate () {
      if (this.paymentMethods.length) {
        this.showWarnUpdatingCard = false
      }
      else if (this.previousPaymentProcessor && this.paymentProcessor) {
        if (this.previousPaymentProcessor !== this.paymentProcessor) {
          this.showWarnUpdatingCard = true
        }
      }

      this.previousPaymentProcessor = this.paymentProcessor
    },
    purchaseSettled (response) {
      const purchase = response.data.purchase || {}
      const reason = response.data.reason
      const index = this.payments.findIndex((item) => item.purchaseId === purchase.id)

      console.log('purchase.settled', response, index)

      if (index !== -1) {
        this.payments[index] = {
          purchaseId: purchase.id,
          id: this.payments[index].id,
          status: purchase.status,
          createdAt: purchase.createdAt,
          error: reason
        }
      }
    }
  }
})
