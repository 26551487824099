<!--suppress ALL -->
<template>
  <Form
    ref="signInForm"
    v-slot="{ errors, setErrors }"
    @submit="logIn">
    <div class="flex items-center mb-3">
      <div class="w-3/12">
        <label for="email">
          {{ $t("forms.login.labels.email") }}
        </label>
      </div>

      <div class="flex-1">
        <YupTooltip :i18n="errors.email">
          <Field
            id="email"
            :placeholder="$t('forms.login.placeholders.email')"
            :rules="emailRules"
            name="email"
            autocomplete="email"
            aria-describedby="emailHelp"
            :validate-on-blur="false"
            :validate-on-change="false"
            :validate-on-input="false"
            type="text"
            class="form-input focus:placeholder-transparent"
            @focus="setErrors(fields);errorMessage = ''"/>
        </YupTooltip>
      </div>
    </div>

    <div class="flex items-center mb-3">
      <div class="w-3/12">
        <label for="password">
          {{ $t("forms.login.labels.password") }}
        </label>
      </div>

      <div class="flex-1">
        <YupTooltip :i18n="errors.password">
          <Field
            id="password"
            :placeholder="$t('forms.login.placeholders.password')"
            :rules="passwordRules"
            :validate-on-blur="false"
            :validate-on-change="false"
            :validate-on-input="false"
            autocomplete="current-password"
            name="password"
            type="password"
            class="form-input focus:placeholder-transparent"
            @focus="setErrors(fields);errorMessage = ''"/>
        </YupTooltip>
      </div>
    </div>

    <div class="flex justify-end mb-6">
      <router-link
        class="text-center text-caption text-link"
        to="/password-recovery"
        @click="loginIsVisible = false">
        {{ $t("auth.login.forgot_password") }}
      </router-link>
    </div>

    <template v-if="errorMessage">
      <AlertMessage
        type="error"
        class="my-3"
        :heading="errorMessage"/>
    </template>

    <button
      type="submit"
      :disabled="loading"
      class="btn btn-primary btn-block mb-6">
      {{ $t("common.login") }}
    </button>

    <div class="text-center text-caption text-link mb-3">
      {{ $t("auth.login.dont_have_an_account_yet") }}
      <a
        class="cursor-pointer"
        @click="loginIsVisible = false; showSignUp()">
        <ins>{{ $t("common.sign_up") }}</ins>
      </a>
    </div>

    <div class="text-center text-caption">
      <VRuntimeTemplate :template="$t('auth.login.terms_agreement', { terms_of_service: termsOfService, privacy_policy: privacyPolicy })"/>
    </div>
  </Form>
</template>

<script>
import { Field, Form } from 'vee-validate'
import { mapState, mapWritableState } from 'pinia'
import { useAuthStore } from '@/stores/auth'
import uniqid from 'uniqid'
import helpers from '@/helpers'
import YupTooltip from '@/components/Functional/YupTooltip'
import AlertMessage from '@/components/Functional/AlertMessage.vue'

export default {
  name: 'LoginForm',
  components: {
    Field,
    Form,
    YupTooltip,
    AlertMessage
  },
  data () {
    return {
      loading: false,
      fields: {
        email: false,
        password: false
      },
      emailRules: this.$yup.string().required().email(),
      passwordRules: this.$yup.string().required().min(6),
      errorMessage: null
    }
  },
  computed: {
    ...mapState(useAuthStore, [
      'isAuthenticated'
    ]),
    ...mapWritableState(useAuthStore, [
      'loginIsVisible',
      'signupIsVisible',
      'pathToRedirect',
      'landingPath'
    ]),
    termsOfService () {
      return `<a
                href="https://one2fan.com/terms"
                target="_blank"
                class="text-primary">
                {{ $t("common.terms_of_service") }}
              </a>`
    },
    privacyPolicy () {
      return `<a
                href="https://one2fan.com/policy"
                target="_blank"
                class="text-primary">
                {{ $t('common.privacy_policy') }}
              </a>`
    }
  },
  watch: {
    isAuthenticated (value) {
      if (value) {
        this.loginIsVisible = false
        // this.$router.push('/')
      }
    }
  },
  mounted () {
  },
  beforeUnmount () {
    this.loading = false
  },
  methods: {
    showSignUp () {
      helpers.showSignUp()
    },
    async logIn (values, { resetForm }) {
      this.loading = true

      const authStore = useAuthStore()

      const loginData = {
        email: values.email,
        password: values.password
      }

      try {
        const isLoginSuccess = await authStore.startSession(loginData)

        if (isLoginSuccess) {
          this.loginIsVisible = false

          this.$notify({
            id: uniqid(),
            group: 'success',
            title: this.$t('common.success'),
            text: this.$t('notifications.login_successful')
          })

          this.$addEvent(['Log in'])

          // Redirect to page user wanted to open before login
          // landingPath - first visited page. Pages Creator and Post are hardcoded
          if (this.landingPath.name === 'Creator' || this.landingPath.name === 'Post') {
            await this.$router.push(this.landingPath.path)
          } else if (this.pathToRedirect?.length) {
            await this.$router.push(this.pathToRedirect)

            this.pathToRedirect = null
          } else {
            await this.$router.push('/')
          }

          resetForm()
        } else {
          this.errorMessage = 'Something went wrong'
        }
      } catch (error) {
        this.errorMessage = error?.message ? error.message : 'Something went wrong'
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
