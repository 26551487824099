import app from '@/main'
import { uniqBy } from 'lodash'
import { defineStore } from 'pinia'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'

export const usePostStore = defineStore({
  id: 'post',
  state: () => ({
    initialPost: {
      id: null,
      userId: null
    },
    user: {},
    tagUsers: [],
    posts: [],
    // Loading states: loaded-all, loaded, loading, loading-more
    loadingState: '',
    newPostExist: null
  }),
  getters: {
    // Unique posts without duplicates
    uniqPosts: (state) => uniqBy(state.posts, 'id'),
    // Posts without initial post
    filteredPosts: (state) => {
      return state.posts.filter(item => item.id !== state.initialPost.id)
    }
  },
  actions: {
    likeToggle (postId) {
      const post = this.posts.find((post) => post.id === postId)
      if (!post) return

      post.liked = !post.liked
      post.likeCount = post.liked ? post.likeCount + 1 : post.likeCount - 1
    },
    addTagsUsers (payload) {
      const users = payload.data.users.filter((user) => !this.tagUsers.some((tagUser) => Number(tagUser.userId) === Number(user.userId)))

      this.tagUsers = [...this.tagUsers, ...users]
    },
    async postGet (id) {
      this.loadingState = 'loading'

      const appStore = useAppStore()
      const authStore = useAuthStore()

      const res = await app.wsp
        .sendRequest({
          data: {
            id: id,
            postUnlockToken: appStore.secretToken,
            installId: authStore.installId ? authStore.installId : null
          },
          method: 'post.get'
        })

      if (res.error) return Promise.reject(Error(res.error.message))

      if (res.data?.user) this.user = res.data.user
      if (res.data?.post) this.initialPost = res.data.post
      if (res.data?.post) this.posts = [...this.posts, ...[res.data.post]]

      return res.data
    },
    async userPostsGet (username = null) {
      this.loadingState = this.filteredPosts.length ? 'loading-more' : 'loading'

      const data = {
        username: this.user.username,
        contentType: this.initialPost.type,
        limit: 9,
        // beforeNumber: this.loadingState === 'loading' ? null : this.posts[this.posts.length - 1].number,
        orderBy: 'score',
        belowScore: this.loadingState === 'loading' ? null : this.posts[this.posts.length - 1].score
      }

      const appStore = useAppStore()
      const authStore = useAuthStore()

      if (appStore.secretToken) {
        data.postUnlockToken = appStore.secretToken
      }

      data.installId = authStore.installId ? authStore.installId : null

      const res = await app.wsp.sendRequest({
        data: data,
        method: 'user.posts.get'
      })

      if (res.error) return Promise.reject(Error(res.error.message))

      const posts = res.data.posts

      if (posts.length) {
        // dispatch('addPosts', posts)
        this.posts = [...this.posts, ...posts]
        this.loadingState = 'loaded'
      } else {
        this.loadingState = 'loaded-all'
      }
    },
    async tagPostsGet (params) {
      this.loadingState = 'loading'

      const appStore = useAppStore()
      const authStore = useAuthStore()

      if (appStore.secretToken) {
        params.postUnlockToken = appStore.secretToken
      }

      params.installId = authStore.installId ? authStore.installId : null

      const response = await app.wsp.sendRequest({
        data: params,
        method: 'tag.posts.get'
      })

      if (response.error) {
        this.loadingState = 'loaded'
        console.log(response.error.message)
      }

      if (response.data.posts.length < params.limit) {
        this.loadingState = 'loaded-all'
      } else {
        this.loadingState = 'loaded'
      }

      this.addTagsUsers(response)
      this.posts = [...this.posts, ...response.data.posts]
    },
    async shareCount ({ postId }) {
      const post = this.posts.find((element) => element.id === postId)
      if (!post) return

      post.shareCount++
    },
    //
    // WS Events
    //
    userStatus (data = {}) {
      if (!data.data) return

      const { userId, status } = data.data

      if (Number(this.user.userId) !== Number(userId)) return
      this.user.online = status === 'online'
    },
    postNew ({ data }) {
      if (!data.post) return

      this.newPostExist = data.post
    },
    messageNew (data) {
      if (this.router.currentRoute.name !== 'Post') {
        return
      }

      const senderId = data.data.message.senderId + ''
      const unreadIncomingMessageCount = data.data.sender.unreadIncomingMessageCount

      // Update number of unread messages from creator
      if (senderId === this.user.userId) {
        this.user.unreadIncomingMessageCount = unreadIncomingMessageCount
      }
    }
  }
})
