<template>
  <div class="container mt-5 p-0 bg-white">
    <div class="header w-full flex items-center justify-between px-4">
      <LinkBack class="pr-3 mr-1 text-gray-900"/>

      <h3 class="flex-1 text-center">
        #{{ $route.params.tagname }}
      </h3>

      <img
        v-if="isAbleToShare"
        alt="Share icon"
        width="20"
        :src="share"
        @click="shareHandle">
    </div>

    <div class="mt-4 flex flex-wrap">
      <div
        v-for="(post, index) in posts"
        :key="post.id"
        class="w-1/3 h-40 border border-white relative"
        @click="openPost(index)">
        <Lock
          v-if="post.locked && !post.videoUrl"
          :price="post.price"/>

        <img
          class="h-full w-full object-center object-cover"
          alt="placeholder"
          title="Title"
          :src="post.videoPreviewImageUrl">

        <div class="absolute left-2 bottom-2 text-caption text-white post-shadow opacity-80">
          {{ parseDuration(post.videoLength) }}
        </div>
      </div>

      <div
        v-show="~loadingState.indexOf('loading')"
        class="h-8 w-full flex">
        <Spinner/>
      </div>

      <Observer
        v-if="loadingState && loadingState !== 'loaded-all'"
        @intersect="fetchPosts"/>

      <div
        v-if="loadingState === 'loaded-all'"
        class="my-4 leading-10 w-full text-caption text-gray-400 text-center"
        :class="{'border-b border-grey-600': posts.length > 0}">
        {{ posts.length > 0 ? $t('common.end_of_list') : $t('common.no_content') }}
      </div>
    </div>

    <Modal
      ref="modalPosts"
      v-slot="{ values, closeModal }"
      name="ThePostsSwiper"
      :is-rounded="false"
      :has-indents="false"
      :is-full-height="true"
      :show-close-cross="false"
      content-class="h-full bg-gray-700"
      position="center">
      <ThePostsSwiper
        :posts="posts"
        :users="tagUsers"
        :show-close="true"
        :values="values"
        @close="closeModal"
        @slide-changed="slideChanged"
        @reach-end="reachEnd"
        @liked="liked"
        @shared="shared"
        @unlocked="unlocked"
        @subscribed="subscribed"/>
    </Modal>
  </div>
</template>

<script>
import Observer from '@/components/Functional/Observer'
import Lock from '@/components/Functional/Lock.vue'
import LinkBack from '@/components/Functional/LinkBack'
import Spinner from '@/components/Functional/Spinner'
import Modal from '@/components/Functional/Modal'
import share from '@/assets/svg/design/share-outline.svg'
import { useMeta } from 'vue-meta'
import { useRoute } from 'vue-router'
import ThePostsSwiper from '@/components/PostsSwiper/ThePostsSwiper'
import { mapActions, mapState, mapWritableState } from 'pinia'
import { usePostStore } from '@/stores/post'
import helpers from '@/helpers'
import uniqid from 'uniqid'

export default {
  name: 'DiscoverTag',
  components: {
    LinkBack,
    Lock,
    Observer,
    Spinner,
    Modal,
    ThePostsSwiper
  },
  setup () {
    const route = useRoute()

    useMeta({
      title: `#${route.params.tagname} videos on One2fan`,
      og: {
        title: `#${route.params.tagname} videos on One2fan`,
        description: `One2fan – get closer to the creators, #${route.params.tagname}`,
        site_name: 'Fancy',
        type: 'website'
      }
    })
  },
  data () {
    return {
      share,
      shareData: {
        title: 'Posts on One2Fan',
        text: `Have a look at the #${this.$route.params.tagname} posts on One2Fan`,
        url: `${process.env.VUE_APP_REDIRECT_URL || 'https://one2fan.com'}/discover/${this.$route.params.tagname}`
      },
      requestParams: {
        name: this.$route.params.tagname.trim(),
        limit: 9,
        offset: 0
      }
    }
  },
  computed: {
    ...mapState(usePostStore, [
      'posts',
      'tagUsers',
      'loadingState'
    ]),
    ...mapWritableState(usePostStore, [
      'posts'
    ]),
    isAbleToShare () {
      return navigator.canShare
    }
  },
  watch: {
    posts (val) {
      this.requestParams.offset = val.length
    },
    async $route (to, from) {
      if (from.name !== to.name) return
      if (!to.params.tagname) return

      this.requestParams = {
        name: to.params.tagname.trim(),
        limit: 18,
        offset: 0
      }

      document.title = `#${to.params.tagname} videos on One2fan`

      await this.$refs.modalPosts.closeModal()
      usePostStore().$reset()

      await this.tagPostsGet(this.requestParams)
    }
  },
  async created () {
    await this.tagPostsGet(this.requestParams)
    await this.tagPostsGet(this.requestParams)

    this.requestParams.limit = 12
  },
  beforeUnmount () {
    usePostStore().$reset()
  },
  mounted () {
    this.invites.initInvites(this.$route)
  },
  methods: {
    ...mapActions(usePostStore, [
      'tagPostsGet',
      'shareCount',
      'likeToggle'
    ]),
    async fetchPosts () {
      if (this.loadingState === 'loading') return

      await this.tagPostsGet(this.requestParams)
    },
    async shareHandle () {
      if (!this.isAbleToShare) {
        this.$notify({
          id: uniqid(),
          group: 'errors',
          title: this.$t('common.warn'),
          text: this.$t('notifications.no_sharing_support')
        })

        return
      }

      await navigator.share(this.shareData)
    },
    openPost (index) {
      this.$refs.modalPosts.openModal({ initialIndex: index })
    },
    async reachEnd () {
      if (this.loadingState !== 'loaded-all') {
        await this.fetchPosts()
      }
    },
    async slideChanged (index) {
      if (this.loadingState === 'loaded-all') return

      if (index >= this.posts.length - 3) {
        await this.fetchPosts()
      }
    },
    liked (postId) {
      this.likeToggle(postId)
    },
    shared (postId) {
      this.shareCount({
        postId
      })
    },
    subscribed (user) {
      this.$refs.modalPosts.closeModal()
      this.$router.push(`/@${user.username}`)
    },
    async unlocked ({ post }) {
      // Update all Posts (even duplicates)
      this.posts.forEach((item, index) => {
        if (item.id === post.id) {
          this.posts[index] = post
        }
      })
    },
    parseDuration (seconds) {
      return helpers.parseDuration(seconds)
    }
  }
}
</script>

