import { useUserStore } from '@/stores/user'
import { useChatStore } from '@/stores/chat'
import { useDialogsStore } from '@/stores/dialogs'
import { useCreatorStore } from '@/stores/creator'
import { useFeedStore } from '@/stores/feed'
import { usePostStore } from '@/stores/post'
import { usePaymentStore } from '@/stores/payment'
import { useQuizStore } from '@/stores/quiz'


export default function () {
  const userStore = useUserStore()
  const chatStore = useChatStore()
  const dialogsStore = useDialogsStore()
  const creatorStore = useCreatorStore()
  const feedStore = useFeedStore()
  const postStore = usePostStore()
  const paymentStore = usePaymentStore()
  const quizStore = useQuizStore()

  // Prepare needed actions for each socket event
  return new Map([
    ['session.start', [
      userStore.sessionStart,
      paymentStore.sessionStart,
      quizStore.sessionStart
    ]],
    ['messages.unread', [
      userStore.messagesUnread
    ]],
    ['dialogs.urgent.counts.changed', [
      userStore.urgentCountsChanged
    ]],
    ['attachment.status', [
      chatStore.attachmentStatus
    ]],
    ['message.status', [
      chatStore.messageStatus
    ]],
    ['dialog.status', [
      chatStore.dialogStatus,
      dialogsStore.dialogStatus
    ]],
    ['dialog.recommended', [
      dialogsStore.dialogRecommended
    ]],
    ['message.new', [
      chatStore.messageNew,
      dialogsStore.messageNew,
      creatorStore.messageNew,
      feedStore.messageNew,
      postStore.messageNew
    ]],
    ['user.changed', [
      userStore.userChanged
    ]],
    ['post.new', [
      postStore.postNew
    ]],
    ['user.status', [
      dialogsStore.userStatus,
      chatStore.dialogUserStatus,
      feedStore.userStatus,
      postStore.userStatus,
      creatorStore.userStatus
    ]],
    ['post.purchase', [
      creatorStore.postPurchase,
      feedStore.postPurchase
    ]],
    ['message.deleted', [
      chatStore.messageDeleted,
      dialogsStore.messageDeleted
    ]],
    ['survey.changed', [
      quizStore.surveyChanged
    ]],
    ['purchase.settled', [
      paymentStore.purchaseSettled
    ]],
    ['user.sync', [
      dialogsStore.userSync,
      chatStore.userSync
    ]]
  ])
}


