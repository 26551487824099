import { useAuthStore } from '@/stores/auth'
import socketActions from '@/plugins/socketActions'
import WebSocketAsPromised from 'websocket-as-promised'
import { watch, nextTick } from 'vue'
import { isFunction } from 'lodash'
import { notify } from '@kyvg/vue3-notification'
import uniqid from 'uniqid'
import { storeToRefs } from 'pinia'

const showSocketRequests = true
const showSocketEvents = false

let wsp = null

export default {
  install: async (app, settings) => {
    let reconnectTimeout = null

    // Reconnection case when page wake up from sleep
    async function handleVisibilityChange () {
      if (document.visibilityState === 'visible') {
        const authStore = useAuthStore()

        const {
          tryingToReconnect
        } = storeToRefs(authStore)

        if (tryingToReconnect.value) {
          return
        }

        await new Promise(resolve => setTimeout(resolve, 250))
        await nextTick()

        if (wsp && (wsp.isClosed || wsp.isClosing)) {
          console.log('Reconnecting socket')

          void reconnect()
        }
      }
    }

    async function reconnect (reconnectAttempts = 1) {
      console.log('RECONNECT ATTEMPT')

      const authStore = useAuthStore()

      const {
        isOnline,
        tryingToReconnect,
        userIsInactive,
        reconectionAttempts
      } = storeToRefs(authStore)

      reconectionAttempts.value = reconnectAttempts

      // Clear timeout if it's the first attempt to reconnect and another reconnection is already set
      if (reconnectAttempts === 1 && reconnectTimeout) {
        console.log('Clearing timeout...')
        clearTimeout(reconnectTimeout)
      }

      // If userIsInactive then no need to recconect (user will refresh page by himself)
      if (userIsInactive.value) {
        if (reconnectTimeout) clearTimeout(reconnectTimeout)

        return
      }

      tryingToReconnect.value = true

      if (reconnectAttempts < 15) {
        console.log(`Attempting to reconnect... (${reconnectAttempts})`)

        reconnectTimeout = setTimeout(async () => {
          if (isOnline.value) {
            try {
              await connect()

              if (reconnectTimeout) {
                clearTimeout(reconnectTimeout)
              }

              reconectionAttempts.value = 0
              location.reload()
            } catch (error) {
              void reconnect(reconnectAttempts + 1)
            }
          }
          else {
            void reconnect(reconnectAttempts + 1)
          }
        }, 3000 * reconnectAttempts)
      }
      else {
        console.log('Max reconnection attempts reached.')
      }
    }

    async function connect () {
      document.removeEventListener('visibilitychange', handleVisibilityChange)

      wsp = new WebSocketAsPromised(settings.socketPath, {
        packMessage: data => JSON.stringify(data),
        unpackMessage: data => JSON.parse(data),
        attachRequestId: (data, requestId) => Object.assign({ id: requestId }, data),
        extractRequestId: data => data && data.id
      })

      const authStore = useAuthStore()

      let pingIntervalId = null
      let unwatch = null

      authStore.isAuthenticated = false

      // Connect
      try {
        await wsp.open()

        if (reconnectTimeout) {
          clearTimeout(reconnectTimeout)
        }

        document.addEventListener('visibilitychange', handleVisibilityChange)
      } catch (error) {
        console.info('Socket encountered error during connection.')
        console.error(error)

        if (process.env.VUE_APP_IS_DEBUG === 'yes') {
          notify({
            id: uniqid(),
            group: 'errors',
            title: 'Error during connection',
            text: error.message
          })
        }
      }

      // Listen ws events and pass data to Pinia's actions
      wsp.onUnpackedMessage.addListener(async message => {
        if (message.error) {
          console.error(message.error)

          // 101 - переданный токен аутентификации недействителен. Недействительный токен можно удалить из локального
          // хранилища. Чтобы отправлять запросы, требующие аутентификации, в установленном соединении необходимо
          // выполнить запрос начала сессии любым способом, за исключением передачи в нём токена,
          // ставшего недействительным. После получения успешного ответа на запрос начала сессии в это соединение можно
          // отправлять запросы, требующие аутентификации.

          // 502 - соединение не аутентифицировано. Чтобы отправлять запросы, требующие аутентификации, в установленном
          // соединении сначала необходимо выполнить запрос начала сессии. После получения успешного ответа на запрос
          // начала сессии в это соединение можно отправлять запросы, требующие аутентификации.
          if (message.error.code === 101) {
            authStore.clearState()
          }
          else if (![105, 147].includes(message.error?.code)) {
            if (process.env.VUE_APP_IS_DEBUG === 'yes') {
              notify({
                id: uniqid(),
                group: 'errors',
                title: `${message.error.code}: ${message.error.message}`,
                text: message.error.description
              })
            }
          }
        }
        else {
          const event = message.method
          const data = message

          const actions = socketActions().get(event)

          if (showSocketEvents) {
            console.log('EVENT:', event)
          }

          if (actions !== undefined) {
            actions.forEach(action => {
              action(data)
            })
          }
        }
      })

      app.wsp = wsp
      app.config.globalProperties.wsp = wsp

      app.reconnect = reconnect
      app.config.globalProperties.reconnect = reconnect

      app.connect = connect
      app.config.globalProperties.connect = connect

      // Save install ID

      const prelandInstallID = localStorage.getItem('prelanding.installId')

      if (prelandInstallID) {
        authStore.installId = prelandInstallID
        localStorage.removeItem('prelanding.installId')
      }

      // При загрузке клиента, если пользователь не залогинен, и отсутствует сохраненный локально Install ID,
      // сделать запрос install.create, и сохранить полученный Install ID в постоянном локальном хранилище.
      if (!authStore.isTokenExist && !authStore.installId) {
        await authStore.installCreate()
      }

      if (!authStore.isTokenExist && authStore.installId) {
        await authStore.sessionGet()
      }

      authStore.isConnected = true

      if (authStore.token && authStore.token.length > 0 && authStore.isConnected) {
        try {
          await authStore.startSession({ token: authStore.token })
        } catch (error) {
          console.error('Start session request ended with error.', error)
        }

        pingIntervalId = setInterval(() => {
          wsp.sendRequest({
            method: 'test.ping'
          })
        }, 1000 * 30)
      }

      // On close handle
      wsp.onClose.addListener(async () => {
        console.log('CONNECTION CLOSED')

        const authStore = useAuthStore()

        authStore.isConnected = false
        authStore.isAuthenticated = false
        wsp.removeAllListeners()

        if (isFunction(unwatch)) {
          unwatch()
        }

        if (pingIntervalId) clearInterval(pingIntervalId)

        if (authStore.tryingToReconnect || authStore.userIsInactive) {
          return
        }

        await reconnect()
      })

      // Sending request
      wsp.onSend.addListener((val) => {
        if (showSocketRequests) {
          console.log('REQUEST:', JSON.parse(val).method)
        }
      })

      // Start pings if token exists
      unwatch = watch(authStore,
        (state) => {
          if (state.token && state.token.length > 0 && state.isAuthenticated && !pingIntervalId) {
            pingIntervalId = setInterval(() => {
              wsp.sendRequest({
                method: 'test.ping'
              })
            }, 1000 * 30)
          }
        },
        { deep: true }
      )

      return wsp
    }

    const packageVersion = localStorage.getItem('packageVersion')

    if (packageVersion === process.env.PACKAGE_VERSION) {
      const wsp = await connect()
      app.provide('wsp', wsp)
    }
  }
}
