<!-- Unlock Post in feed -->
<template>
  <Form
    id="credits-form-1"
    v-slot="{ errors, setErrors }"
    :initial-values="initialValues"
    @submit="unlockPost">
    <div class="flex items-center justify-between mb-1.5">
      <h2 class="h2">
        {{ $t('creator.unlock.post.heading') }}
      </h2>

      <CloseCrossButton @click="$emit('close')"/>
    </div>

    <!-- Pay with credtis if enough -->
    <p class="mb-5">
      <template v-if="isEnoughMoneyInitial">
        {{ $t('creator.unlock.post.confirmation_1', { price: thePost.price }) }}
      </template>

      <template v-else>
        <span
          v-html="$t('creator.unlock.post.confirmation_2', { price: thePost.price, price_in_currency: thePost.priceInCurrency })"/>
      </template>
    </p>

    <!-- Show terms checkbox if no saved card -->
    <YupTooltip
      v-if="!paymentMethods.length && !isEnoughMoneyInitial"
      :i18n="errors.terms">
      <div class="flex items-start mb-8">
        <div class="h-5 flex items-center">
          <Field
            id="post-terms"
            type="checkbox"
            name="terms"
            class="mt-1.5 h-4 w-4 text-primary border border-primary rounded focus:ring-blue-300"
            :value="true"
            :rules="rules.terms"
            @focus="setErrors(fieldsToReset)"/>
        </div>

        <div class="ml-3 text-sm">
          <label
            for="post-terms"
            class="font-medium text-gray-600 text-caption leading-4">
            <VRuntimeTemplate
              :template="$t('creator.unlock.post.terms_agreement', { terms_of_service: termsOfService, privacy_policy: privacyPolicy })"/>
          </label>
        </div>
      </div>
    </YupTooltip>

    <!-- Pay with card if card there is -->
    <div
      v-if="paymentMethods.length && !isEnoughMoneyInitial"
      class="border-t border-gray-200 py-2 mb-2 flex items-center whitespace-nowrap flex justify-between">
      <span class="text-gray-500">
        {{ $tc('user.your_cards.heading', 1) }}
      </span>

      <div class="flex justify-end items-center">
        <span class="ml-2 font-mono text-caption text-gray-500">
          <template v-if="paymentProcessor === 'stripe'">
            **** **** **** {{ activePaymentMethod.card.last4 }}
          </template>

          <template v-else-if="paymentProcessor === 'exactly'">
            {{ activePaymentMethod.card.maskedNumber }}
          </template>
        </span>
      </div>
    </div>

    <!-- States of action end -->
    <template v-if="!isLoading">
      <AlertMessage
        v-if="successMessage"
        type="success"
        class="mb-2.5"
        :heading="successMessage"/>

      <AlertMessage
        v-else-if="isFailed || (errorMessage && !isPending)"
        type="error"
        class="mb-2.5"
        :heading="errorMessage || 'Previous transaction failed'"
        :text="errorDescription"/>

      <AlertMessage
        v-else-if="isPending"
        type="warning"
        class="mb-2.5"
        heading="Transaction pending, check later or contact online support"/>

      <AlertMessage
        v-else-if="showWarnUpdatingCard && !isEnoughMoney"
        type="info"
        class="mb-3 -mt-4"
        heading="When making a payment, you will have to update your card details once more!"/>
    </template>

    <button
      type="submit"
      class="btn btn-block btn-primary mb-3 h-[44px] relative"
      :disabled="isLoading || isPending || successMessage">
      <Spinner
        v-show="isLoading"
        class="absolute inset-0 m-auto"/>

      <span v-show="!isLoading">{{ $t('common.confirm') }}</span>
    </button>

    <button
      type="button"
      class="btn btn-block btn-secondary"
      :disabled="isLoading"
      @click="$emit('close')">
      {{ $t('common.cancel') }}
    </button>
  </Form>
</template>

<script>

import CloseCrossButton from '@/components/ModalContents/parts/CloseCrossButton'
import { mapActions, mapState } from 'pinia'
import { useUserStore } from '@/stores/user'
import { useAppStore } from '@/stores/app'
import { Field, Form } from 'vee-validate'
import YupTooltip from '@/components/Functional/YupTooltip'
import { usePaymentStore } from '@/stores/payment'
import { useAuthStore } from '@/stores/auth'
import AlertMessage from '@/components/Functional/AlertMessage.vue'
import Spinner from '@/components/Functional/Spinner.vue'
import uniqid from 'uniqid'
import app from '@/main'

export default {
  name: 'UnlockPostContent',
  components: {
    CloseCrossButton,
    YupTooltip,
    Field,
    Form,
    AlertMessage,
    Spinner
  },
  props: {
    post: {
      type: Object,
      required: true,
      default: () => ({
        userId: null
      })
    }
  },
  emits: ['close', 'unlocked'],
  data () {
    return {
      isEnoughMoneyInitial: null,
      successMessage: null,
      errorMessage: null,
      errorDescription: null,
      thePost: {},
      isLoading: false,
      initialValues: {
        terms: false
      },
      fieldsToReset: {
        terms: false
      },
      rules: {
        terms: this.$yup.boolean()
          .required({ key: 'yup.custom.terms_and_conditions', values: {} })
          .oneOf([true], () => ({ key: 'yup.custom.terms_and_conditions', values: {} }))
      }
    }
  },
  computed: {
    ...mapState(useAppStore, [
      'currentTime',
      'endlessPaymentCreated'
    ]),
    ...mapState(useUserStore, [
      'balance',
      'premiumSupportAgentUserId'
    ]),
    ...mapState(usePaymentStore, [
      'paymentProcessor',
      'activePaymentMethod',
      'paymentMethods',
      'postPayments',
      'showWarnUpdatingCard'
    ]),
    ...mapState(useAuthStore, [
      'installId'
    ]),
    postPayment () {
      return this.postPayments.find(item => item.id.includes('post_' + this.post.id))
    },
    secondsLeftFromPayment () {
      if (this.postPayment) {
        return this.currentTime - this.postPayment.createdAt
      }
      else {
        return null
      }
    },
    isPending () {
      return this.postPayment && ['created', 'payment_pending', 'payment_accepted'].includes(this.postPayment.status)
    },
    isFailed () {
      return this.postPayment && ['failed', 'cancelled'].includes(this.postPayment.status)
    },
    termsOfService () {
      return `<a
                href="https://one2fan.com/terms"
                target="_blank"
                class="text-primary">
                {{ $t("common.terms_of_service") }}
              </a>`
    },
    privacyPolicy () {
      return `<a
                href="https://one2fan.com/policy"
                target="_blank"
                class="text-primary">
                {{ $t('common.privacy_policy') }}
              </a>`
    },
    isEnoughMoney () {
      return this.balance >= this.thePost.price
    }
  },
  watch: {
    async postPayment (payment) {
      if (payment?.status === 'succeeded' && !this.successMessage) {
        this.purchaseDelete(payment.purchaseId)
        this.successMessage = 'Payment successful'

        setTimeout(async () => {
          const updatedPost = await this.getPost(this.post.id)

          if (updatedPost) {
            this.$emit('unlocked', updatedPost)
          }

          this.$emit('close')
        }, 1500)

        this.isLoading = false
      }
      else if (['failed', 'cancelled'].includes(payment?.status)) {
        this.errorMessage = payment?.error || 'Payment failed'
        this.purchaseDelete(payment.purchaseId)
        this.isLoading = false
      }
    }
  },
  beforeMount () {
    this.thePost = JSON.parse(JSON.stringify(this.post))
    this.isEnoughMoneyInitial = this.isEnoughMoney
  },
  async mounted () {
    if (this.postPayment) {
      const purchaseId = this.postPayment.purchaseId
      await this.purchaseGet(purchaseId)

      if (this.postPayment.status === 'created') {
        await this.purchaseCancel(purchaseId)
          .then(() => {
            this.purchaseDelete(purchaseId)
          })
      }
    }
  },
  methods: {
    ...mapActions(usePaymentStore, [
      'paymentMethodsGet',
      'checkoutSessionCreate',
      'postPurchase',
      'purchaseGet',
      'purchaseCreate',
      'purchaseDelete',
      'purchaseCancel'
    ]),
    ...mapActions(useUserStore, [
      'meGet'
    ]),
    async unlockPost () {
      if (!this.isLoading) {
        this.isLoading = true

        this.errorMessage = null
        this.errorDescription = null
        this.successMessage = null

        const data = {
          userId: String(this.post.userId),
          number: this.post.number,
          cost: this.post.price
        }

        // Update balance
        await this.meGet()

        if (!this.isEnoughMoney) {
          data.purchaseCreditsIfNotEnough = true

          // Re-check payments methods
          if (!this.activePaymentMethod.id) {
            await this.paymentMethodsGet()
          }
        }

        if (!this.isEnoughMoney && this.activePaymentMethod.id) {
          data.paymentMethodId = this.activePaymentMethod.id

          // Check payment status or init one
          if (this.postPayment) {
            await this.purchaseGet(this.postPayment.purchaseId)
          }
          else {
            await this.purchaseCreate({ id: `post_${this.post.id}_${uniqid()}` })
          }

          if (this.endlessPaymentCreated) {
            await new Promise(resolve => setTimeout(resolve, 1000 * 5))
            this.isLoading = false
            return
          }

          // Remove failed purchase if payment initialized again
          if (['failed', 'cancelled'].includes(this.postPayment?.status)) {
            this.purchaseDelete(this.postPayment.purchaseId)
          }

          if (this.postPayment?.purchaseId) {
            data.purchaseId = this.postPayment.purchaseId
          }
        }
        else {
          if (!this.isEnoughMoney) {
            const postId = this.post.id
            const cost = this.post.price

            await this.checkoutSessionCreate({
              successUrl: `${window.origin}/${postId}?unlock=true`,
              cancelUrl: `${window.origin}/${postId}`,
              savePaymentMethod: true,
              productType: 'credits',
              productId: cost
            })

            return
          }
        }

        this.postPurchase(data)
          .then((res) => {
            this.errorMessage = null
            this.errorDescription = null
            this.successMessage = 'Payment successful'
            this.isLoading = false

            setTimeout(() => {
              this.$emit('unlocked', res?.post)
              this.$emit('close')
            }, 1500)
          })
          .catch(async (error) => {
            // Failed to accept the payment (purchase failed)
            if (error?.code === 147) {
              this.errorMessage = error?.message
              this.errorDescription = error?.description
            }
            // The product is already purchased (purchase failed)
            else if (error?.code === 105) {
              this.errorMessage = 'Product is already purchased'

              const updatedPost = await this.getPost(this.post.id)

              if (updatedPost) {
                this.$emit('unlocked', updatedPost)
                this.$emit('close')
              }
            }
            // Payment is pending
            else if (error?.code === 149) {
              //
            }
            else {
              this.errorMessage = 'Error occured'
            }

            // Remove payment
            if ([147, 105].includes(error?.code)) {
              this.purchaseDelete(this.postPayment.purchaseId)
            }
          })
          .finally(async () => {
            if (this.postPayment) {
              await new Promise(resolve => setTimeout(resolve, 1000))
              await this.purchaseGet(this.postPayment.purchaseId)

              // If purchase not completed -> wait n seconds to show loading
              if (!['succeeded', 'failed', 'cancelled'].includes(this.postPayment?.status)) {
                await new Promise(resolve => setTimeout(resolve, 1000 * 10))
              }
            }

            this.isLoading = false
          })
      }
    },
    async getPost (id) {
      const res = await app.wsp
        .sendRequest({
          data: {
            id: id,
            installId: this.installId ? this.installId : null
          },
          method: 'post.get'
        })

      if (res.error) {
        return
      }

      return res.data.post
    }
  }
}
</script>
