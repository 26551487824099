<template>
  <div class="leading-5 text-sm lg:text-base relative pr-2">
    <button
      class="btn p-1 m-0 absolute -right-5 -top-5 rounded-full"
      @click="$emit('close')">
      <InlineSvg
        class="opacity-80"
        :src="require('@/assets/svg/design/times-circle.svg')"/>
    </button>

    <VRuntimeTemplate
      :template="$t('cookies_reminder', { privacy_and_cookie_policy: privacyAndCookiePolicy })"/>
  </div>
</template>

<script>

export default {
  name: 'CookiesReminderContent',
  components: {},
  emits: ['close'],
  computed: {
    privacyAndCookiePolicy () {
      return `<a
                href="https://one2fan.com/policy"
                target="_blank"
                class="text-primary">
                {{ $t('common.privacy_and_cookie_policy') }}
              </a>`
    }
  },
  mounted () {},
  methods: {}
}
</script>

