<template>
  <div
    ref="signUpContent"
    style="-webkit-overflow-scrolling: touch">
    <div
      v-if="showHeading"
      class="flex items-center justify-between mb-6">
      <h2 class="h1">
        {{ isDatingEnabled ? $t('auth.signup.sign_up_for_start_dating') : $t('auth.signup.sign_up_for_free') }}
      </h2>

      <button
        class="btn py-2 pl-3 pr-0 cursor-pointer"
        type="button"
        @click="$emit('close')">
        <ImageLoader v-slot="{ setLoaded }">
          <InlineSvg
            :src="require('@/assets/svg/design/cross.svg')"
            class="text-gray-900"
            @loaded="setLoaded"/>
        </ImageLoader>
      </button>
    </div>

    <Form
      ref="signInForm"
      v-slot="{ errors, setErrors }"
      :initial-values="initialValues"
      @submit="register">
      <div class="flex items-center mb-3">
        <div class="w-4/12">
          <label
            for="public_name">
            {{ $t('forms.signup.labels.public_name') }}
          </label>
        </div>

        <div class="flex-1">
          <YupTooltip :i18n="errors.publicName">
            <Field
              id="public_name"
              :placeholder="$t('forms.signup.placeholders.public_name')"
              :rules="rules.publicName"
              name="publicName"
              :validate-on-blur="false"
              :validate-on-change="false"
              :validate-on-input="false"
              type="text"
              class="form-input focus:placeholder-transparent"
              @focus="setErrors(fields);errorMessage = ''"/>
          </YupTooltip>
        </div>
      </div>

      <div class="flex items-center mb-3">
        <div class="w-4/12">
          <label
            for="age">
            {{ $t('forms.signup.labels.age') }}
          </label>
        </div>

        <div class="flex-1">
          <YupTooltip :i18n="errors.age">
            <Field
              id="age"
              :placeholder="$t('forms.signup.placeholders.age')"
              :rules="rules.age"
              :validate-on-blur="false"
              :validate-on-change="false"
              :validate-on-input="false"
              name="age"
              type="tel"
              pattern="[0-9]*"
              novalidate
              class="form-input focus:placeholder-transparent"
              @focus="setErrors(fields);errorMessage = ''"/>
          </YupTooltip>
        </div>
      </div>

      <div class="flex items-center mb-3">
        <div class="w-4/12">
          <label
            for="email">
            {{ $t('forms.signup.labels.email') }}
          </label>
        </div>

        <div class="flex-1">
          <YupTooltip :i18n="errors.email">
            <Field
              id="email"
              :placeholder="$t('forms.signup.placeholders.email')"
              :rules="rules.email"
              name="email"
              :validate-on-blur="false"
              :validate-on-change="false"
              :validate-on-input="false"
              type="text"
              class="form-input focus:placeholder-transparent"
              @focus="setErrors(fields);errorMessage = ''"/>
          </YupTooltip>
        </div>
      </div>

      <div class="flex items-center mb-3">
        <div class="w-4/12">
          <label for="password">
            {{ $t('forms.signup.labels.password') }}
          </label>
        </div>

        <div class="flex-1">
          <YupTooltip :i18n="errors.password">
            <Field
              id="password"
              :placeholder="$t('forms.signup.placeholders.password')"
              :rules="rules.password"
              :validate-on-blur="false"
              :validate-on-change="false"
              :validate-on-input="false"
              name="password"
              type="password"
              class="form-input focus:placeholder-transparent"
              @focus="setErrors(fields);errorMessage = ''"/>
          </YupTooltip>
        </div>
      </div>

      <template v-if="errorMessage">
        <AlertMessage
          type="error"
          class="my-3"
          :heading="errorMessage"/>
      </template>

      <button
        :class="{ 'mt-15': !showHeading }"
        :disabled="loading"
        class="btn btn-primary btn-lg btn-block mb-3">
        {{ $t('common.continue') }}
      </button>

      <div class="text-center text-caption text-link mb-3">
        {{ $t('auth.signup.already_have_an_account') }}

        <a
          class="cursor-pointer"
          @click="showLogIn(); signupIsVisible = false">
          <ins>{{ $t('auth.signup.login_link') }}</ins>
        </a>
      </div>

      <YupTooltip :i18n="errors.terms">
        <div class="flex items-start mb-1 px-4">
          <div class="h-5 flex items-center">
            <Field
              id="terms"
              type="checkbox"
              name="terms"
              class="mt-1.5 h-4 w-4 text-primary border border-primary rounded focus:ring-blue-300"
              :value="true"
              :rules="rules.terms"
              @focus="setErrors(fields);errorMessage = ''"/>
          </div>

          <div class="ml-3 text-sm">
            <label
              v-if="secretToken"
              for="terms"
              class="font-medium text-gray-600 text-caption leading-4">
              <VRuntimeTemplate
                :template="$t('auth.signup.terms_agreement_short', { terms_of_service: termsOfService, privacy_policy: privacyPolicy })"/>
            </label>

            <label
              v-else
              for="terms"
              class="font-medium text-gray-600 text-caption leading-4">
              <VRuntimeTemplate
                :template="$t('auth.signup.terms_agreement', { terms_of_service: termsOfService, privacy_policy: privacyPolicy })"/>
            </label>
          </div>
        </div>
      </YupTooltip>
    </Form>
  </div>
</template>

<script>
import { Field, Form } from 'vee-validate'
import ImageLoader from '@/components/Functional/ImageLoader'
import { useAuthStore } from '@/stores/auth'
import { mapWritableState, mapActions, mapState } from 'pinia'
import helpers from '@/helpers'
import { useAppStore } from '@/stores/app'
import YupTooltip from '@/components/Functional/YupTooltip'
import AlertMessage from '@/components/Functional/AlertMessage.vue'

export default {
  name: 'LoginForm',
  components: {
    Field,
    Form,
    YupTooltip,
    ImageLoader,
    AlertMessage
  },
  props: {
    showHeading: {
      type: Boolean,
      default: true
    }
  },
  emits: ['close'],
  data () {
    return {
      loading: false,
      fields: {
        public_name: false,
        username: false,
        email: false,
        age: false,
        password: false,
        terms: false
      },
      errorMessage: null,
      initialValues: {},
      rules: {
        publicName: this.$yup.string().required(),
        username: this.$yup
          .string()
          .required()
          .matches(/^\S*$/, () => ({ key: 'yup.custom.no_whitespaces', values: {} }))
          .matches(/^[_a-zA-Z\d]+$/, () => ({ key: 'yup.custom.only_latin', values: {} })),
        email: this.$yup.string().required().email(),
        age: this.$yup
          .number()
          .min(18)
          .max(99)
          .typeError({ key: 'yup.number.default', values: {} })
          .required()
          .integer(),
        password: this.$yup.string().required().min(6),
        terms: this.$yup.boolean()
          .required({ key: 'yup.custom.terms_and_conditions', values: {} })
          .oneOf([true], () => ({ key: 'yup.custom.terms_and_conditions', values: {} }))
      }
    }
  },
  computed: {
    ...mapState(useAppStore, [
      'showAppealPWA',
      'isDatingEnabled',
      'secretToken'
    ]),
    ...mapWritableState(useAppStore, [
      'pwaPromptsCounter'
    ]),
    isProduction () {
      return process.env.NODE_ENV === 'production'
    },
    ...mapWritableState(useAuthStore, [
      'loginIsVisible',
      'signupIsVisible'
    ]),
    termsOfService () {
      return `<a
                href="https://one2fan.com/terms"
                target="_blank"
                class="text-primary">
                {{ $t("common.terms_of_service") }}
              </a>`
    },
    privacyPolicy () {
      return `<a
                href="https://one2fan.com/policy"
                target="_blank"
                class="text-primary">
                {{ $t('common.privacy_policy') }}
              </a>`
    }
  },
  beforeUnmount () {
  },
  mounted () {
  },
  methods: {
    ...mapActions(useAuthStore, [
      'signUp'
    ]),
    showLogIn () {
      helpers.showLogIn()
    },
    async register (values, { resetForm }) {
      this.loading = true

      function generateUsernameForDating () {
        return `d_${String(Math.random()).padEnd(12, '0').slice(2, 12)}`
      }

      function generateUsername () {
        return `u_${String(Math.random()).padEnd(12, '0').slice(2, 12)}`
      }

      const data = {
        sex: 1,
        name: values.publicName,
        username: this.isDatingEnabled ? generateUsernameForDating() : generateUsername(), // values.username
        email: values.email,
        age: +values.age,
        password: values.password
      }

      try {
        await this.signUp(data)

        this.$addEvent(['Sign up'])

        if (this.pwaPromptsCounter < 2) {
          setTimeout(() => {
            if (this.showAppealPWA) {
              this.pwaPromptsCounter = 2
            }
          }, 1000 * 60)
        }

        if (this.isProduction) {
          // eslint-disable-next-line no-undef
          gtag('event', 'Submit', {
            event_category: 'Form',
            event_label: 'Registration'
          })
        }

        await this.$emit('close')
        resetForm()

        if (this.isDatingEnabled) {
          await this.$router.push('/your-interests')
        } else {
          await this.$router.push('/details')
        }
      } catch (error) {
        this.errorMessage = error?.message ? error.message : 'Something went wrong'
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
