<template>
  <Header
    name="Sign in"
    class="mb-6"/>

  <div class="container pb-4">
    <h2 class="h1 mb-6">
      {{ $t("common.login") }}
    </h2>

    <LoginForm :show-heading="false"/>
  </div>
</template>

<script>
import LoginForm from '@/components/LoginForm'
import Header from '@/components/Functional/Header'
import helpers from '@/helpers'

export default {
  name: 'LogIn',
  components: {
    LoginForm,
    Header
  },
  data () {
    return {}
  },
  computed: {
    isDev () {
      return process.env.NODE_ENV === 'development'
    }
  },
  created () {
    if (process.env.VUE_APP_MAIN_ORIGIN && window.location.origin !== process.env.VUE_APP_MAIN_ORIGIN && !this.isDev) {
      const url = helpers.prepareURLtoRedirect()
      window.location.replace(url)
    }
  },
  methods: {}
}
</script>

