import app from '@/main'
import { uniqBy } from 'lodash'
import { defineStore } from 'pinia'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'

const feedType = new Map([
  ['public', {
    number: 0,
    stateName: 'publicFeed',
    stateViewedIds: 'publicViewedIds',
    usersState: 'publicUsers'
  }],
  ['private', {
    number: 1,
    stateName: 'privateFeed',
    stateViewedIds: 'privateViewedIds',
    usersState: 'privateUsers'
  }]
])

export const useFeedStore = defineStore({
  id: 'feed',
  state: () => ({
    publicFeed: [],
    privateFeed: [],
    publicUsers: [],
    privateUsers: [],
    publicViewedIds: [],
    privateViewedIds: [],
    // Loading states: loaded, loading, loading-more
    loadingStatus: ''
  }),
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
        paths: [
          'publicViewedIds',
          'privateViewedIds'
        ]
      }
    ]
  },
  getters: {},
  actions: {
    setItemStatusByIndex ({ type, index, status }) {
      this[feedType.get(type).stateName][index].status = status
    },
    addUsers ({ type, items }) {
      this[feedType.get(type).usersState].push(...items)

      // Remove duplicates
      this[feedType.get(type).usersState] = uniqBy(this[feedType.get(type).usersState], 'username')
    },
    // Change viewed statues to past
    updateStatues ({ type, oldStatus, newStatus }) {
      this[feedType.get(type).stateName] = this[feedType.get(type).stateName].map(
        item => item.status === oldStatus ? { ...item, status: newStatus } : item
      )
    },
    setUserStatus ({ userId, status }) {
      feedType.forEach((element) => {
        const user = this[element.usersState].find((user) => Number(user.userId) === Number(userId))
        if (user) user.online = status === 'online'
      })
    },
    likeToggle ({ type, postId }) {
      const post = this[feedType.get(type)?.stateName]?.find((post) => post.id === postId)
      if (!post) return

      post.liked = !post.liked
      post.likeCount = post.liked ? post.likeCount + 1 : post.likeCount - 1
    },
    // Get feed items
    // See docs here: docs/feed.md

    /*
    * Feed Statuses

    - excluded: all new posts
    - viewed: what was opened in slider
    - past: what was opened and sent
    * */
    async feed ({ type, limit = 10 }) {
      const feed = this[feedType.get(type).stateName]

      this.loadingStatus = feed.length ? 'loading-more' : 'loading'

      const excludedPostIds = feed
        .filter(item => item.status === 'excluded')
        .map(item => item.id)

      // console.log('Просмотренные, но не отправленные посты:', this[feedType.get(type).stateViewedIds].toString())
      // console.log('Исключенные посты:', excludedPostIds.toString())

      const appStore = useAppStore()
      const authStore = useAuthStore()

      const res = await app.wsp
        .sendRequest({
          data: {
            type: feedType.get(type).number,
            limit: limit,
            viewedPostIds: this[feedType.get(type).stateViewedIds],
            excludedPostIds: excludedPostIds,
            postUnlockToken: appStore.secretToken,
            installId: authStore.installId ? authStore.installId : null
          },
          method: 'feed'
        }).finally(() => {
          this.loadingStatus = 'loaded'
        })

      if (res.error) {
        console.log(res.error.message)
      }

      this.addUsers({ type: type, items: res.data.users })

      // console.log('Просмотренные посты:', this[feedType.get(type).stateViewedIds].toString())
      this[feedType.get(type).stateViewedIds] = []

      // Change viewed statues to past
      this.updateStatues({
        type: type,
        oldStatus: 'viewed',
        newStatus: 'past'
      })

      // Set status for all new coming post as excluded
      const items = res.data.posts.map(item => {
        return {
          ...item,
          status: 'excluded'
        }
      })

      if (items.length > 0) {
        this[feedType.get(type).stateName].push(...items)
      }

      // console.log('Загруженные посты:', res.data.posts.map(item => item.number).toString())
      // console.log('Все посты:', this[feedType.get(type).stateName].map(item => item.number).toString())
    },
    async postLike ({ type, postUserId, postNumber, postId }) {
      const res = await app.wsp.sendRequest({
        data: { postUserId, postNumber },
        method: 'post.like'
      })

      if (res.error) {
        console.log(res.error.message)
      }

      this.likeToggle({ type, postId, liked: true })
    },
    async postUnlike ({ type, postUserId, postNumber, postId }) {
      const res = await app.wsp.sendRequest({
        data: { postUserId, postNumber },
        method: 'post.unlike'
      })

      if (res.error) {
        console.log(res.error.message)
      }

      this.likeToggle({ type, postId, liked: false })
    },
    async shareCount ({ type, postId }) {
      const post = this[feedType.get(type)?.stateName]?.find((element) => element.id === postId)
      if (!post) return

      post.shareCount++
    },
    userSetSubscription (userId) {
      const user = this.publicUsers.find((user) => user.userId === userId)
      if (!user) return

      user.subscribed = true
    },
    //
    // WS Events
    //
    userStatus (data = {}) {
      if (!data.data) return

      this.setUserStatus(data.data)
    },
    // Update post in list
    postPurchase (data = {}) {
      const index = this.privateFeed.findIndex((item) => item.id === data?.data.post.id)

      if (index !== -1) {
        this.privateFeed.splice(index, 1, data?.data.post)
      }
    },
    messageNew (data) {
      if (!['Home', 'Following'].includes(this.router.currentRoute.name)) {
        return
      }

      const senderId = data.data.message.senderId
      const unreadIncomingMessageCount = data.data.sender.unreadIncomingMessageCount

      if (this.router.currentRoute.name === 'Home') {
        const index = this.publicUsers.findIndex((user) => user.userId === senderId)

        if (index !== -1) {
          this.publicUsers[index].unreadIncomingMessageCount = unreadIncomingMessageCount
        }
      } else {
        const index = this.privateUsers.findIndex((user) => user.userId === senderId)

        if (index !== -1) {
          this.privateUsers[index].unreadIncomingMessageCount = unreadIncomingMessageCount
        }
      }
    }
  }
})
