<template>
  <div class="p-6">
    <div class="flex justify-center mb-6">
      <InlineSvg
        :src="require('@/assets/svg/design/verified.svg')"
        class="w-20"/>
    </div>

    <h3 class="text-xl pr-7 mb-6 text-center">
      {{ $t('common.confirmed_account') }}
    </h3>

    <ul class="custom-list-style flex flex-col space-y-2.5 mb-7">
      <li>
        {{ $t('creator.verified.verified_identity') }}
      </li>

      <li>
        {{ $t('creator.verified.via_id') }}
      </li>

      <li>
        {{ $t('creator.verified.posted_tiktok_bio', { social_name: socialName }) }}
      </li>
    </ul>

    <div>
      <a
        class="btn btn-primary btn-block py-3 mb-2"
        target="_blank"
        href="https://one2fan.com/how">
        {{ $t('nav.sidebar.become_a_creator') }}
      </a>

      <button
        class="btn btn-secondary btn-block py-3"
        type="button"
        @click="$emit('close')">
        {{ $t('common.cancel') }}
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'VerifiedContent',
  components: {},
  props: {
    socialName: {
      type: String,
      default: ''
    }
  },
  emits: ['close'],
  data () {
    return {}
  },
  computed: {},
  mounted () {}
}
</script>

<style scoped>
  .custom-list-style {
    padding-left: 20px;
  }
  .custom-list-style li {
    list-style-image: url("@/assets/svg/design/checkbox-check.svg");
    padding-left: 10px;
  }
</style>

